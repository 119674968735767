import React, { useState, useEffect } from "react";
import Trek from "./components/Trek";
import "./trek.scss";
import { useSwipeable } from "react-swipeable";

const Treks = () => {
  const [step, setstep] = useState(0);
  

  // use effect for auto slide
  // useEffect(() => {
  //   const intreval = setInterval(() => {
  //     if (paused === false) {
  //       setStep(step + 1);
  //     }
  //   }, 5000);

  //   return () => {
  //     if (intreval) clearInterval(intreval);
  //   };
  // });
  const handlers = useSwipeable({
    onSwipedLeft: () => setStep(step + 1),
    onSwipedRight: () => setStep(step - 1),
  });

  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);

  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);

    window.addEventListener("resize", resizeW); // Update the width on resize

    return () => window.removeEventListener("resize", resizeW);
  });

  const setStep = (activeStep) => {
    if (deviceSize >= 1340) {
      if (activeStep < 0) {
        activeStep = treks.length - 4;
      } else if (activeStep > treks.length - 4) {
        activeStep = 0;
      }
    } else if (deviceSize >= 1020 && deviceSize < 1340) {
      if (activeStep < 0) {
        activeStep = treks.length - 3;
      } else if (activeStep > treks.length - 3) {
        activeStep = 0;
      }
    } else if (deviceSize >= 840 && deviceSize < 1020) {
      if (activeStep < 0) {
        activeStep = treks.length - 2;
      } else if (activeStep > treks.length - 2) {
        activeStep = 0;
      }
    } else if (deviceSize >= 660 && deviceSize < 840) {
      if (activeStep < 0) {
        activeStep = treks.length - 2;
      } else if (activeStep > treks.length - 2) {
        activeStep = 0;
      }
    } else {
      if (activeStep < 0) {
        activeStep = treks.length - 1;
      } else if (activeStep > treks.length - 1) {
        activeStep = 0;
      }
    }

    setstep(activeStep);
    //  else if (deviceSize <= 320) {
    //   if (way === "left") {
    //     step > 0 ? setstep(step - 1) : setstep(1);
    //   } else if (way === "right") {
    //     step < treks.length - 1 ? setstep(step + 1) : setstep(0);
    //   }
    // }
    // else if (deviceSize >= 1200) {
    //   if (way === "left") {
    //     step > 0 ? setstep(step - 1) : setstep(4);
    //   } else if (way === "right") {
    //     step < treks.length - 4 ? setstep(step + 1) : setstep(0);
    //   }
    // } else if (deviceSize <= 1020) {
    //   if (way === "left") {
    //     step > 0 ? setstep(step - 1) : setstep(5);
    //   } else if (way === "right") {
    //     step < treks.length - 3 ? setstep(step + 1) : setstep(0);
    //   }
    // } else if (deviceSize <= 680 && deviceSize <= 320) {
    //   if (way === "left") {
    //     step > 0 ? setstep(step - 1) : setstep(7);
    //   } else if (way === "right") {
    //     step < treks.length - 1 ? setstep(step + 1) : setstep(0);
    //   }
    // } else if (deviceSize <= 1000 && deviceSize <= 650) {
    //   if (way === "left") {
    //     step > 0 ? setstep(step - 1) : setstep(8);
    //   } else if (way === "right") {
    //     step < treks.length - 1 ? setstep(step + 1) : setstep(0);
    //   }
    // }
  };

  const treks = [
    {
      id: 1,
      title: "Trek vallée des roses",
      desc: "Trek de la vallée des roses",
      
      bgi: "/assets/img/valroseCar.jpg",
      src: "/Trek_de_la_vallee_des_roses",
    },
    {
      id: 2,
      title: "Trek désert",
      desc: "Trek de la vallée du Draa et ksour de Zagora",
     
      bgi: "/assets/img/desrtCard.jpg",
      src: "/Trek_desert",
    },
    {
      id: 3,
      title: "Trek berbère ",
      desc: "Trek des villages berbères avec visite de Marrakech",
      
      bgi: "/assets/img/bcImg.jpg",
      src: "/Trek_berbere",
    },
    {
      id: 4,
      title: "Trek atlantique",
      desc: "Trek côte Atlantique avec la visite d’Essaouira",
      
      bgi: "/assets/img/Atlantiqued’EssaouiraCard.jpg",
      src: "/Trek_atlantique",
    },
    {
      id: 5,
      title: "Trek Toubkal",
      desc: "Trek vallée des roses Trek vallée des roses",
      
      bgi: "/assets/img/cardToubqal.jpg",
      src: "/Trek_Toubkal",
    },
    {
      id: 6,
      title: "Trek Bouguemmez",
      desc: " Randonnée pédestre ou équestre M’goun Vallées et villages Traversée Nord Sud Tabant ou Timyt - Ighrem Akdim",
      
      bgi: "/assets/img/cardBouguemez.jpg",
      src: "/Trek_Bouguemmez",
    },
    {
      id: 7,
      title: "Trek Moyen Atlas",
      desc: "Trek vallée des roses Trek vallée des roses",
      
      bgi: "/assets/img/cardMoyenAtlas.jpg",
      src: "/Trek_Moyen_Atlas",
    },
    {
      id: 8,
      title: "Circuit en 4x4 Desert",
      desc: "Grand sud en 4x4",
      
      bgi: "/assets/img/4X4-DESERT.jpg",
      src: "/Grand_sud_en_4x4",
    },
    {
      id: 9,
      title: "Circuit en 4x4 Haut atlas",
      desc: "Circuits 4x4 Haut atlas ",
      
      bgi: "/assets/img/Card4x4HautAtlas.jpg",
      src: "/Circuit_4x4_Haut_atlas",
    },
    {
      id: 10,
      title: "Toubkal 15J",
      desc: "Le grand tour de Toubkal 15 j.",
      
      bgi: "/assets/img/cardToubqal.jpg",
      src: "/Toubkal_15J",
    },
    {
      id: 11,
      title: "3 vallées de Toubkal 8J",
      desc: "3 vallées de Toubkal 8J",
      
      bgi: "/assets/img/Card3valeedeTobkal.jpg",
      src: "/vallees_de_Toubkal_8J",
    },
    // {
    //   id: 12,
    //   title: "Traversée du Désert (VTT)",
    //   desc: "Circuit Vtt - Traversée du Désert: Taouz – Zagora- Chgaga – 8J",
    //   
    //   bgi: "/assets/img/CardVTTDesert.jpg",
    //   src: "/Traversee_du_Desert",
    // },
    {
      id: 13,
      title: "Le grand sud 8J (VTT)",
      desc: "Vélo de route dans le grand Sud Marocain: Ouarzazate - Jbel Saghro- Zagora ",
   
      bgi: "/assets/img/CardVttGrandSud.jpg",
      src: "/Le_grand_sud",
    },
    {
      id: 14,
      title: "Le Saghro 8J",
      desc: "Le Saghro 8J",
      
      bgi: "/assets/img/CardSaghro.jpg",
      src: "/Le_Saghro_8J",
    },
    {
      id: 15,
      title: "Le Rif : Chefchaoune",
      desc: `Randonnée dans le Rif: région de Chafchaoune.`,
      
      bgi: "/assets/img/Cardchefchaouen.jpg",
      src: "/Rif_Chefchaoune",
    },
    {
      id: 16,
      title: "Trek AMZMIZE",
      desc: "Trek Berber AMZMIZE",
      
      bgi: "/assets/img/Berber/6.jpg",
      src: "/Trek_berber_amzmize",
    },
     // {
    //   id: 17,
    //   title: "Moyen Atlas 8J",
    //   desc: "Moyen Atlas 8J",
  
    //   bgi: "/assets/img/4X4-DESERT.jpg",
    //   src: "/Circuits_pistes",
    // },
  ];

  return (
    <div className="treks" id="Treks">
      <div className="treks__top">
        <div className="top__left">
          <h3 className="top__miniTitle">Top Trek</h3>
          <h1 className="top__title">Nos randonnées populaires</h1>
          <p className="top__desc">
            Nous mettons à votre disposition notre expérience pour vous proposer
            des trekking, des rondonnées, du tourisme sportif et de la
            découverte au cœur de montagne au du désert marocain. Vous serez
            accompagnés de notre équipe de muletiers et chameliers,sans oublier
            nos cuisiniers. Nous vous proposons des treks et des circuits à
            pieds et en 4+4 et en VTT et d'autres Formule à la demande..
          </p>
        </div>
        <div className="top__right">
          <button className="top__btn" onClick={(e) => setStep(step + 1)}>
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <button className="top__btn" onClick={(e) => setStep(step - 1)}>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div className="treks__bottom">
        <div
          {...handlers}
          className="treks__wrapper"
          style={{ transform: `translateX(-${step * 320}px)` }}
         
        >
          {treks.map((trek) => (
            <Trek
              key={trek.id}
              title={trek.title}
              desc={trek.desc}
              para={trek.para}
              bgi={trek.bgi}
              src={trek.src}
            />
          ))}
        </div>
      </div>
      <div className="trek__overlay"></div>
    </div>
  );
};

export default Treks;
