import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { Link, NavLink } from "react-router-dom";
import Logo from "../logo/Logo";
import "./header.scss";
// import video from "../../../public/assets/img/Maroc.mp4";
const Header = () => {
  const [side, setside] = useState(false);
  const toggle = () => {
    setside(!side);
  };
  return (
    <div className="header">
      <i
        className={`fa-solid fa-bars burger ${side && "hide"}`}
        onClick={toggle}
      ></i>
      <div className={`side__menu ${side && "toggle"}`}>
        <ul className="side__menu__list">
          <li className="side__menu__item ">
            <i className="fa-solid fa-x x_marq" onClick={toggle}></i>
          </li>
          <li className="side__menu__item">
            <a href="#">
              <i className="fa-solid fa-house-chimney"></i>
              Home
            </a>
          </li>
          <li className="side__menu__item">
            <Link to="/InfosPratiques">
              <i className="fa-solid fa-person-hiking"></i>
              Infos Pratique
              </Link>
          </li>
          <li className="side__menu__item">
            <a href="#Treks">
              <i className="fa-solid fa-map-location-dot"></i>
              Nos Treks
            </a>
          </li>
          <li className="side__menu__item">
            <a href="#Contactus">
              <i className="fa-solid fa-comments"></i>
              Contact us
            </a>
          </li>
          {/* <li className="side__menu__item">
            <a href="#">
              <i className="fa-solid fa-map-location-dot"></i>
              Nos Treks
            </a>
          </li> */}
          <li className="side__menu__item">
            <a href="#Aboutus">
              <i className="fa-solid fa-user-large"></i>
              About us
            </a>
          </li>
        </ul>
      </div>
      <div className="header__navBar">
        <h1 className="navBar__logo">
          <Logo />
        </h1>

        <ul className="navBar__menu">
          <li className="menu__item">
            <a href="#">Home</a>
          </li>
          <li className="menu__item">
            <a href="#Aboutus">About Us</a>
          </li>
          <li className="menu__item">
            <a href="#Treks">Nos Treks</a>
          </li>
          <li className="menu__item">
            <a href="#Contactus">Contact</a>
          </li>
        </ul>
      </div>
      <video
        className="header__video"
        src="/assets/img/Maroc.mp4"
        muted
        loop
        autoPlay
      ></video>
      <div className="header__overlay"></div>
      <div className="header__texte">
        <h2>explorer la  </h2>
        <h3>culture marocaine</h3>
        <p>
          Vous aimez la nature sauvage, les dunes, le désert et la montagne,
          vous rêvez de vivre la vraie vie des habitants du maroc, leurs
          costumes et leurs traditions. Nous avons créé pour vous, une structure
          des guides du montagne deplomés de centre de formation (le centre de
          formation au Maroc agréé pour les métiers de montagne).
        </p>
        <a className="header__text_btn" href="#Treks">
          Explore
        </a>
      </div>
    </div>
  );
};

export default Header;
