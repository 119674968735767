import React from "react";
import "./card.scss";

const Card = ({ img, name, star, testimon, job }) => {
  const stars = (star) => {
    for (let index = 0; index <= star; index++) {
      <i key={index} className="fa-solid fa-star"></i>;
    }
  };
  return (
    <div className="card">
      <img className="card__img" src={img} />
      <div className="cared__wraper">
        <div className="card__top">
          <h2 className="card__name">{name}</h2>
          <p className="card__job">{job}</p>
        </div>

        <div className="card__bottom">
          <p className="card__bottom__star">
            <i className="fa-solid fa-star" style={{ color: "gold" }}></i>
            <i className="fa-solid fa-star" style={{ color: "gold" }}></i>
            <i className="fa-solid fa-star" style={{ color: "gold" }}></i>
            <i className="fa-solid fa-star" style={{ color: "gold" }}></i>
            <i className="fa-solid fa-star" style={{ color: "gold" }}></i>
          </p>
          <p className="card__bottom__testimon">{testimon}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
