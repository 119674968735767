import React, { useState } from "react";
import Logo from "../logo/Logo";
import PageTreksFirst from "./Components/PageTreksFirst";
import "./PageTrek.scss";
import { Link } from "react-router-dom";
/* import Prix from "./Components/Prix"; */

const PagrTrek = ({ TrekTitle, TrekName, TrekBckImg, TrekValiable, prix4, prixP4, children }) => {
  const [Bar, setBar] = useState(false);
  return (
    <div className="PagrTrek">
      <div

        className="PagrTrek__hero"
        style={{ backgroundImage: `url(${TrekBckImg})` }}
      >
        <div className="PagrTrek__hero__Bar">
          <button
            className={`PagrTrek__hero__burger ${Bar && "Hide"}`}
            onClick={() => setBar(!Bar)}
          >
            <i className="fa-solid fa-bars"></i>
          </button>
          <ul className={`PagrTrek__Bar__listItem ${Bar && "showListItem"}`}>
            <h3 className="pagrTrek__hero_valiable">{TrekValiable}</h3>
            <i className="fa-solid fa-x" onClick={() => setBar(!Bar)}></i>
            <Link to="/" className="PagrTrek__Bar__Item">
              <li>Home</li>
            </Link>

            <a href="#Contact us" className="PagrTrek__Bar__Item">
              <li>Contact us</li>
            </a>

            <Link to="/InfosPratiques" className="PagrTrek__Bar__Item">
              <li>Infos Pratiques</li>
            </Link>
          </ul>
        </div>
        <div className="PagrTrek__hero__Top">
          <div className="PagrTrek__hero__Top__left">
            <Logo />
          </div>
          <div className="PagrTrek__hero__Top__midlle">
            <ul className="PagrTrek__hero__Top__list">
              <Link to="/" className="PagrTrek__hero__Top__listItem">
                <li>Home</li>
              </Link>
              <a href="#Contact us" className="PagrTrek__hero__Top__listItem">
                <li>Contact us</li>
              </a>

              <Link to="/InfosPratiques" className="PagrTrek__hero__Top__listItem">
                <li>Infos Pratiques</li>
              </Link>
            </ul>
          </div>
          <div className="PagrTrek__hero__Top__Right">
            <h3 className="pagrTrek__hero_valiable">{TrekValiable}</h3>
          </div>
        </div>
        <div className="PagrTrek__hero__bottom">
          <div className="PagrTrek__hero__Midlle">
            <span className="GoldenColor">
              <p className="GoldenLine"></p>
              TREK GUID
            </span>
            <h1 className="pagerTrek__hero__Title">{TrekTitle}</h1>
            <a href="#PagrTrek__body" className="pagerTrek__hero__scroll">
              Scroll Down
              <i className="pagerTrek__hero__scroll__arrow fa-solid fa-arrow-down-long"></i>
            </a>
          </div>
        </div>
        <div className="PageTrek__overlay"></div>
      </div>
      <div className="PagrTrek__body" id="PagrTrek__body">
        {TrekName.map((jour) => (
          <PageTreksFirst
            key={jour.jour}
            jour={jour.jour}
            jourTitle={jour.jourTitle}
            jourDesc={jour.jourDesc}
            jourImg={jour.jourImg}
            reverce={jour.reverce}
          />
        ))}
      </div>
      {/*      <Prix
      prix4={prix4}
      prixP4={prixP4}
      children={children}/> */}
      <footer
        className="PagrTrek__footer"
        style={{ backgroundImage: `url(${TrekBckImg})` }}
      >
        <div className="PagrTrek__footer__Left">

          <a
            href="https://wa.me/+33_6 33 68 16 48"
            className="footer__icon"
            target="_blank"
            rel="noreferrer"
          >
            <i className=" fa-brands fa-whatsapp"></i>
          </a>
          <a className="footer__icon" href="https://web.facebook.com/profile.php?id=100009187353740">
            <i className="footer__icon fa-brands fa-facebook-f"></i>
          </a>
          <Link to="/" className="footer__icon">
            <i className=" fa-solid fa-house-chimney"></i>
          </Link>

        </div>
        <div className="footer__right" id="Contact us">
          <form
            className="contactus__right"
            action="https://formsubmit.co/aitelmahjoubali6@gmail.com"
            method="POST"
          >
            <h2 className="contactus__formTitle">LAISSEZ-NOUS UN MESSAGE</h2>
            <div className="contactus__rightRow">
              <input
                type="text"
                className="contactus__formInput"
                placeholder=" "
                name="name"
                required
              />
              <label htmlFor="" className="contactus__lbl">
                Name
              </label>
            </div>

            <div className="contactus__rightRow">
              <input
                type="email"
                className="contactus__formInput"
                placeholder=" "
                name="email"
                required
              />
              <label htmlFor="" className="contactus__lbl">
                E-mail
              </label>
            </div>

            <div className="contactus__rightTxtareaRow">
              <textarea
                className="contactus__formInput"
                cols="25"
                rows="4"
                placeholder=" "
                name="message"
                required
              ></textarea>
              <label htmlFor="" className="contactus__lbl">
                Message
              </label>
            </div>

            <button
              type="submit"
              className="contactus__formbtn"
            // onClick={(e) => handleSubmit(e)}
            >
              SEND
            </button>
          </form>
        </div>

        <div className="PageTrek__overlay"></div>
      </footer>
    </div>
  );
};

export default PagrTrek;
