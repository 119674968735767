import "./App.scss";
import Contactus from "./Sections/Contactus/Contactus";
import Header from "./Sections/Header/Header";
import Services from "./Sections/OurServices/Services";
import Testemonial from "./Sections/Testemonials/Testemonial";
import Treks from "./Sections/Trek/Treks";
import { BrowserRouter as Rtouer, Route, Routes } from "react-router-dom";
import PagrTrek from "./Sections/PageTreks/PagrTrek";
import Aboutus from "./Sections/Aboutus/Aboutus";
import SurMesure from "./Sections/SurMesure/SurMesure";
import InfosPratiques from "./Sections/InfosPratiques/InfosPratiques"
import ScrollToTop from "./Sections/Scrooltotop";

function App() {
  const Trek_de_la_vallée_des_roses = [
    {
      jour: "1",
      jourTitle:
        "Jour 1 : Marrakech – Col de Tichka – Ouarzazate – Vallée de Dadès (326 km)",
      jourDesc:
        "Départ vers 8h, Direction le col de Tichka (2260 m) ; le plus haut col routier du Maroc, paysage panoramique grandiose, après le col les paysages seront semi désertique du grand sud Marocain versant sud. Déjeuner à Ouarzazate, puis continuation vers Kelaa de M’Gouna, avant d’arriver à la vallée du Dadès avec ses belles casbahs. Nuit sous tentes. 7 h de route.",
      jourImg: "/assets/img/valle_de_rose/1.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: "Jour 2 : La vallée de Dades – La vallée des roses Agurzegua",
      jourDesc:
        "Départ de notre randonnée de la vallée de Dadès par la traversée de plusieurs cultures en terrasse puis nous quittons cette belle vallée pour un paysage aride pendant 3h, enfin nous découvrons la belle vallée verdoyante du M’Goun. Déjeuner au bord de la vallée au village Ighrm Aqdim, puis à nouveau 1H30 de marche pour atteindre le village de Aguerzaka, sans oublier de prévoir des tennis puisque nous serons obligés de traverser de la rivière plusieurs fois. La durée:5h30 de marche, Dénivelé : 500m de montée et 400m de descente. Nuit sous les tentes.",
      jourImg: "/assets/img/valle_de_rose/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "Jour 3 : Agurzegua – la vallée d’Almdoune",
      jourDesc:
        "Nous quittons cette vallée ; très verdoyante avec ses belles casbahs et gorges, pour monter 300 mètres vers le col qui nous conduit par une forêt de genévriers vers la vallée d’Ameskar. A nouveau une belle vallée de Almdoune, avec une centaine de maisons en forme de casbahs en pisé. Déjeuner dans la valléeTemtda, puis continuation sur un bon et grand sentier jusqu’au village Alemdoune 1700m. La durée : 5h de marche Dénivelé : 400m de montée et 300m de descente. Nuit sous les tentes.",
      jourImg: "/assets/img/valle_de_rose/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle:
        "Jour 4 : Almdoune / Vallée El Quati –Les gorges de lauriers roses- Boutghrar",
      jourDesc:
        "Nous descendons tranquillement une vallée de lauriers roses (prévoir de traverser les gorges avec des sandales). Des gorges petites, mais très dépaysantes par ses lauriers roses pour arriver à la vallée de Elhout avec ses belles casbahs. Un grand village avec plusieurs belles maisons, Déjeuner au bord de la rivière auprès du village Aznag, nous traversons ensuite après 1H de marche on arrivera au village de Boutarhrar -La durée : 5H de marche Dénivelé : 500m de descente. Nuit sous les tentes.",
      jourImg: "/assets/img/valle_de_rose/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "Jour 5 : Boutaghrar – Tourbiste – Hdida",
      jourDesc:
        "Une superbe balade au cœur de la vallée des roses avec ses les casbahs qui deviennent de plus en plus nombreuses. Les champs de roses font leur apparition autour des cultures de céréales (orge, maïs, pomme de terre….). Ces roseraies sont utilisées pour la fabrication des produits de rose. Descente de la vallée jusqu’au Tourbiste puis Hdida. Nuit en gîte au village.",
      jourImg: "/assets/img/valle_de_rose/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle:
        "Jour 6 : Hdida – Ouarzazate – kasbah d’Ait Ben Haddou – Marrakech",
      jourDesc:
        "Transfert en voiture, direction de Ouarazazat, puis la visite da kasbah d’ait Ben Haddou ; célèbre par les nombreux films qui y ont vu le jour. Cette kasbah du 11e siècle a été déclarée patrimoine mondiale de l’humanité classée par l’UNESCO, puis en profitant des somptueux paysages que nous offre l’Atlas, nous repartrons à Marrakech. Nuit en hôtel avec le petit déjeuner. 6h30 de route.",
      jourImg: "/assets/img/valle_de_rose/6.jpg",
      reverce: false,
    },
   
  ];

  const Trek_désert = [
    {
      jour: "1",
      jourTitle: "Jour 1 : Arriveé à Marrakech (ou Ouarzazate)",
      jourDesc: "Transfert de l'aéroport à l'hôtel. Repas du soir libre.",
      jourImg: "/assets/img/berberCard.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: "Jour 2 : Marrakech – Ouarzazate - Zagora",
      jourDesc:
        "Départ matinal vers Ouarzazate en passant par le col de Tichka et en traversant les villages. Déjeuner sur place. L’après-midi traversée de paysages de western et de paysages lunaires avant d’arriver à la belle palmeraie de la vallée du Draa. Route Zagora (point de halte des caravaniers de Tombouctou) avant d’arriver à Imi Ntakate, point de départ de notre trek.",
      jourImg: "/assets/img/desert/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle:
        "Jour 3 : Imi Ntakkate - Dunes de Bouygaouarne – 5 heures de marche",
      jourDesc:
        "Après le petit déjeuner, chargement des dromadaires et commencement de la marche pour rejoindre la palmeraie d’Ait Essfoul riche avec ses cultures d’orge et de blé. Traversée des ksours (villages fortifiés) avant d’arriver aux dunes de Bouygaouarne. Déjeuner en cours de route.",
      jourImg: "/assets/img/desert/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle:
        "Jour 4 : Dunes de Bougayoir - Zaoyat Sidi Salah - Tidri - 5 h de marche",
      jourDesc:
        "Nous empruntons les sillons dunaires jusqu’au lit de l’oued Draa pour atteindre le plateau désertique à proximité du village Zaoyat Sidi Salah. Bivouac au pied des pentes sablonneuses de Tidri. Nuit en bivouac.",
      jourImg: "/assets/img/desert/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "Jour 5 : Tidri – Erg Essahel - 4 heures de marche",
      jourDesc:
        "Cap plus au sud, notre caravane s’élance dans l’infini saharien entre belles dunes et plateaux arides. Bivouac au cœur de l’erg Essahel. Nuit en bivouac.",
      jourImg: "/assets/img/desert/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "Jour 6 : Erg Essahel - Hassi Laanithie - 5 heures de marche",
      jourDesc:
        "En prenant le cap sud-ouest, nous quittons l’erg Essahel à la découverte de petites agglomérations en ruine qui étaient habitées avant la désertification. Nous poursuivons notre rando autour de Hassi Lâanithie. Nuit au bivouac.",
      jourImg: "/assets/img/desert/6.jpg",
      reverce: true,
    },
    {
      jour: "7",
      jourTitle: "Jour 7 : Hassi Laanithie - Bounou",
      jourDesc:
        "Départ tranquille au pas des dromadaires pour rejoindre la kasbah souterraine de Bounou. Nuit au camping.",
      jourImg: "/assets/img/desert/7.jpg",
      reverce: false,
    },
    {
      jour: "8",
      jourTitle: "Jour 8 : Bounou - Oulad Driss - Ouarzazate - Marrakech",
      jourDesc:
        "Dernière balade saharienne autour de la palmeraie d’Oulad Driss puis route pour Marrakech via Ouarzazate et la vallée du Drâa. Déjeuner en chemin, arrivée dans l’après-midi. Environ 7h30 de route. Installation à l'hôtel. Repas du soir libre.",
      jourImg: "/assets/img/desert/8.jpg",
      reverce: true,
    },
    {
      jour: "9",
      jourTitle: "Marrakech",
      jourDesc:
        "Selon les horaires de vol, transfert à l'aéroport. Fin de nos services.",
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: false,
    },
  ];
  const Trek_berbère = [
    {
      jour: "1",
      jourTitle: "Jour 1 : Arriveé à Marrakech",
      jourDesc:
        "Arrivée à Marrakech, transfert de l’aéroport à l’hôtel situé à 5mn à pied de la place de Jammaa el Fnaa. Dîner libre.",
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: "Jour 2 : Marrakech – Oukaimeden",
      jourDesc:
        "Le matin, visite guidée de Marrakech (les tombeaux Saadiens, le palais Bahia et les souks de Marrakech). Après le déjeuner chez l’habitant, transfert vers le point de départ du trek. Une petite montée à pied pour la mise en jambe et rejoindre la station de ski de Marrakech Oukaimeden. Repas et nuit en gite chez l’habitant.",
      jourImg: "/assets/img/Berber/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "Jour 3: Oukaimeden – Imsker – 4 heures de marche",
      jourDesc:
        "Après le petit déjeuner, descente de la vallée d’Ousertek en direction du village d’Imsker dans la vallée d’Imnan. Nuit dans le gite.",
      jourImg: "/assets/img/Berber/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "Jour 4 : Imsker – Ouanskra – 5 heures de marche",
      jourDesc:
        "Après le petit déjeuner, départ vers la vallée d’Imnan une superbe vallée riche avec ses arbres fruitiers et traversée des villages d’Arg, Amssakrou, Ikess pour arriver à Ouanskra. Déjeuner en cours de route. Nuit au gite.",
      jourImg: "/assets/img/Berber/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "Jour 5 : Ouanskra – Aremd – 5 heures de marche",
      jourDesc:
        "Départ matinal pour traverser le col de Tamatert et rejoindre la vallée D’Imlil et après une petite montée on rejoint le village d’Aremd. Déjeuner en cours de route et nuit chez habitant.",
      jourImg: "/assets/img/Berber/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "Jour 6 : Aremd – Vallée d’Azzaden - 5 heures de marche",
      jourDesc:
        "Départ matinal pour traverser le col de Mzik une montée super sympa qui domine les deux vallées d’Aït Mezzane et d’Azzaden. Après une descente aussi sympa que la montée, arrivée à la vallée d’Azzaden. Déjeuner en cours de route et nuit au gite.",
      jourImg: "/assets/img/Berber/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "Jour 7 : Vallée d’Azzaden – Marrakech – 3 heures de marche",
      jourDesc:
        "Départ vers le col de Tacht pour redescendre vers la vallée d’Aït Mizzane et rejoindre Imi Nou’oughlade. Après le déjeuner, transfert à Marrakech. Dîner libre. Nuit à l’hôtel.",
      jourImg: "/assets/img/Berber/7.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "Jour 8 : Marrakech",
      jourDesc:
        "Selon les horaires de vol, transfert à l'aéroport. Fin de nos services.",
      jourImg: "/assets/img/berberCard.jpg",
      reverce: false,
    },
  ];
  const Trek_atlantique = [
    {
      jour: "1",
      jourTitle: "Jour 1 : Marrakech",
      jourDesc:
        "Arrivée à Marrakech transfert au riad au centre de la ville ocre. Nuit au riad. Dîner libre.",
      jourImg: "/assets/img/atlantique/1.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle:
        "Jour 2 : Marrakech – Essaouira - Diabate – 3 heures de marche",
      jourDesc:
        "Départ matinal vers la ville d’Essaouira. Après 3h de route, arrivée à Essaouira. Visite du port, des remparts et de la scala d’Essaouira. Déjeuner au marché de poisson (poisson grillé). Après-midi, petite marche sur la côte pour se rendre au bivouac de Diabate et rejoindre le cuisinier et les dromadaires qui transportent les bagages. Nuit au bivouac.",
      jourImg: "/assets/img/atlantique/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "Jour 3 : Diabate - Sidi Kaouki – 6 heures de marche",
      jourDesc:
        "Après le petit déjeuner, départ encore sur la côte et en face des dunes de sable et rencontre de temps en temps avec les pêcheurs dans leurs villages pour arriver à Sidi Kaouki une grande plage magnifique pour rejoindre notre point de bivouac. Nuit en tentes. Déjeuner en cours de route.",
      jourImg: "/assets/img/atlantique/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "Jour 4 : Sidi Kaouki - Sidi Mbarek – 4 heures de marche",
      jourDesc:
        "Départ matinal sur la côte pour rejoindre les dunes de sable et les cascades de Sidi Mbarek .Déjeuner sur place. Après midi libre pour profiter de la plage. Nuit en tente.",
      jourImg: "/assets/img/atlantique/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle:
        "Jour 5 : Sidi Mbarek - Sidi Ahmed Sayeh – 5 heures 30 de marche",
      jourDesc:
        "Distribution des pique-niques après le petit déjeuner et marche au bruit des vagues sur les falaises qui dominent la côte avant de rejoindre la plage d’Iftane pour pique-niquer et après continuer la marche vers Sidi Ahmad Sayeh. Nuit au bivouac.",
      jourImg: "/assets/img/atlantique/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "Jour 6 : Sidi Ahmed Sayeh - Tafdna – 6 heures de marche",
      jourDesc:
        "Départ matinal vers la plage et montée pour rejoindre la forêt d’arganier et la vue magnifique sur la plage de Tafdna et le petit port de sardines. Pique-nique en cours de route avant de redescendre vers la plage et rejoindre le village pour passer la nuit chez l’habitant.",
      jourImg: "/assets/img/atlantique/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "Jour 7 : Tafdna - Marrakech",
      jourDesc:
        "Apres le petit déjeuner, chargement des bagages dans le transport et retour à Marrakech.",
      jourImg: "/assets/img/atlantique/7.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "Jour 8 : Marrakech",
      jourDesc:
        "Selon les horaires de vol, transfert à l'aéroport. Fin de nos services.",
      jourImg: "/assets/img/atlantique/8.jpg",
      reverce: false,
    },
  ];
  const Trek_Bouguemmez = [
    {
      jour: "1",
      jourTitle: "Jour 01 : Arrivée à Marrakech",
      jourDesc:
        "Accueil et assistance à l’aéroport. Transfert. Dîner et nuit à l’hôtel.",
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle:
        "Jour 02 : Marrakech / Aït Bouguemmez Timiyt (1850 m) (5h30 de route)",
      jourDesc:
        "Départ matinal de Marrakech. Transfert à la vallée heureuse, via Azilal. Déjeuner en cours de route. Arrivée à Aït Bouguemmez, installation balade l’après – midi aux alentours des villages de Timiyte ou Aït Ziri. Rencontre avec notre équipe de muletiers et cuisinier. Nuit au gîte ou bivouac.",
      jourImg: "/assets/img/Bouguemmez/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "Jour 03 : Timiyte /Aït Imi  / Aïn Aflafal (2336 m)",
      jourDesc: `Départ en randonnée, après le chargement de nos bagages et matériel sur le dos des mulets. Après le village d'Aït Imi commence la montée vers le col Tizi-n-Aït Imi (2900 m), par un sentier bien entretenu et facile. Une fois sur le col, vue panoramique sur toute la chaîne de l’Atlas, ses versants Nord et Sud. A partir du col, la descente facile vers l’Oued M’goun. Bivouac près de la belle source d’Aflafal. 6 h de marche.
Dénivelée positive /:/ 1000 m dénivelée négative //: 640 m `,
      jourImg: "/assets/img/Bouguemmez/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "Jour 04 : Aïn Aflafal / Waouchki (2033 m)",
      jourDesc: `Changement de versant, de paysage et de population, c’est la vallée du M’goun (Tribu Imgounen), où vit une tribu de Haut Atlas Central, agriculteurs et nomades, dont les traditions diffèrent de celles des tribus du versant nord. Descente animée avec la présence humaine : agriculteurs, villageois, enfants…. Nombreux arrêts. 5 h de marche. Bivouac.

Dénivelée positive : 00 m dénivelée négative : 300 m`,
      jourImg: "/assets/img/Bouguemmez/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "Jour 05 : Waouchki / Tighanimine (1790 m)",
      jourDesc: `Etape très variée et animée dans les gorges du M’goun, avec la traversée de plusieurs villages et la marche dans l’eau. Nombreux arrêts. 4 h de marche. Nuit au gîte ou bivouac.

Dénivelée positive : 00 m dénivelée négative : 245 m`,
      jourImg: "/assets/img/Bouguemmez/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "Jour 06 : Tighanimine / Igherm Akdim (1680m)",
      jourDesc: `Toujours dans les gorges de M’goun avec la marche dans l’eau pour une grande partie de la journée. Les passages sont impressionnants des escaliers rocheux et des gorges (Ajaabou). Nuit en bivouac ou au gîte à Igherm Akdim. 5 h de marche.
Dénivelée positive : 00 m dénivelée négative : 245 m`,
      jourImg: "/assets/img/Bouguemmez/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "Jour 07 : Igherm Akdim. Transfert à Ouarzazate / Marrakech",
      jourDesc:
        "Après le petit déjeuner, votre véhicule vous emmènera à Ouarzazate ou Marrakech. Dîner et nuit à l’hôtel.",
      jourImg: "/assets/img/desert/8.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "Jour 08 : Transfert à l’aéroport et assistance au départ.",
      jourDesc:
        "Après le petit déjeuner, selon les heures du vol, transfert à l’aéroport.Fin de nos services.",
      jourImg: "/assets/img/valle_de_rose/6.jpg",
      reverce: false,
    },
  ];
  const Trek_Toubkal = [
    {
      jour: "1",
      jourTitle: "Jour 01 : Arrivée à Marrakech.",
      jourDesc: `Accueil et assistance à l'aéroport. Transfert et nuit à l'hôtel.`,
      jourImg: "/assets/img/berberCard.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle:
        "Jour 02 : Marrakech – Imi Oughlad (1350 m) – Col de Tizi n Tacht (2300 m)- Tizi Oussem (1800 m)",
      jourDesc: `Accueil et assistance à l'aéroport. Départ de Marrakech en Taxi ou en minibus avec votre guide accompagnateur en direction d’Imi-Oughlad. (01h30 de route en véhicule). Rencontre avec votre Equipe de muletiers et cuisinier. Chargement des bagages et départ en randonnée. Passage par le Col  Tizi  de Tacht 2300 mètres d'altitude. Nuit en Bivouac ou en gîte chez l'habitant 1800 m, au village de Tizi Oussem.
1h30 de véhicule 6 heures de marche`,
      jourImg: "/assets/img/toubkal/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle:
        "Jour 03 : Tizi Oussem  (1800 m) - Azib Tamsoult (2350 m) – Tizi n Izgaren (3000 m), Bivouac.",
      jourDesc: `Départ du village de Tizi Oussem, via Azib Tamsoult, les cascades et arrivée à Tizi n Izgaren. Montée dénivelée 500 m, nuit en bivouac à Tizi n Izgaren (3000m) (En cas d’intempéries bivouac à Azib Tamsoult)
5 heures de marche.`,
      jourImg: "/assets/img/toubkal/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle:
        "Jour 04 : Tizi n Izgaren (3000 m) – Tizi n Aguelzim (3650 m) – Refuge de Toubkal (3200 m). Bivouac ou refuge.",
      jourDesc: `Azib Tamsoult par le col de Tizi n Aguelzim - Refuge de Toubkal. Nuit au bivouac à côté du refuge de Toubkal. Descente sur le refuge de Toubkal (3200m). Nuit au refuge. (Dénivelée positive 700 m dénivelée négative 300 m).
5- 6 heures de marche.`,
      jourImg: "/assets/img/toubkal/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle:
        "Jour 05 : Refuge (3200m) – Ascension du Sommet (4165 m)- Refuge Toubkal",
      jourDesc: `Ascension du mont Toubkal et descente sur le village d’Aremed ou d'Imlil. Nuit au village en bivouac ou gîte chez l'habitant. (Dénivelée positive 970, dénivelée négative 2250 m )
06- 07 heures de marche.`,
      jourImg: "/assets/img/toubkal/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "Jour 06 : Refuge de Toubkal (3200 m) – Aremed (1945 m)",
      jourDesc: `Après le petit déjeuner, départ sur Aremed. Déjeuner à Sidi Chamharouch, saint guérisseur enterré à côté de la rivière. et descente sur la vallée d’Imlil et Aremed village pour la nuit. Bivouac ou chez l’habitant. 05 heurs de marche.
Possibilité de rejoindre Marrakech . Si d’autres personnes souhaitent encore faire de la marche, une visite du village d’Aremed et ses environs, ou encore aller jusqu'au col de Tamatart (2300m) sont faisables.
(Si retour sur Marrakech 30 minutes de marche 01h30 heures de véhicule pour Marrakech).`,
      jourImg: "/assets/img/toubkal/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: `Jour 07 : Aremed (1945 m) - Imlil (1800 m) – Tizi-n-Tamatert (2800 m) Tacheddirt (2300 m)- Marrakech.`,
      jourDesc: `Petit déjeuner. Nous franchissons le col de Tamatert pour aller dans la vallée d’ Mezzine. Déjeuner à Tacheddirt et nos véhicules arrivent pour rejoindre Marrakech dans le milieu d’après-midi.
Dîner et nuit à l'hôtel.
Temps de marche : 4 à 5 heures
02h00 heures de véhicule pour Marrakech.`,
      jourImg: "/assets/img/toubkal/7.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "Jour 08 : Transfert départ à l'aéroport.",
      jourDesc: `Petit déjeuner et départ à l’aéroport.`,
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: false,
    },
  ];
  const Trek_Moyen_Atlas = [
    {
      jour: "1",
      jourTitle:
        "Jour 1 : Arrivée à Fès ou à Marrakech ou à Fez, accueil par notre guide, transfert et nuit à l’hôtel.",
      jourDesc: ``,
      jourImg: "/assets/img/mAtlas/1.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle:
        "Jour 2 : Transfert vers Khénifra puis vers le village de Jnane Masse, le point de départ de notre randonnée. Nuit chez l’habitant.",
      jourDesc: ``,
      jourImg: "/assets/img//mAtlas/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle:
        "Jour 3:Village de Jnane Masse 1050 m ---Boumzil 1250m- Les trois lacsTIGALMAMINE 163Om : 6h de marche.+350M",
      jourDesc: `Nous poursuivons notre randonnée dans le pays des Zayanes. Nous marchons à travers des pâturages et des forêts de chênes verts et de cèdre où nous aurons sans doute l'occasion d'apercevoir des singes, troupeaux de moutons et de chèvres. Cette région est un grand domaine de pâture, très fréquenté par les transhumants. Notre journée sera particulièrement agréable : fraîcheur, nature sublime, sur un sentier des chemins sans grande difficulté. Bivouac aux bord des lacs de Tiglmamine. 5H de marche`,
      jourImg: "/assets/img//mAtlas/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle:
        "Jour 4 : De TIGALMAMINE165Om--- lac d' AZIGZA-130Om-5h de marche. Dénivelé faible",
      jourDesc: `Nous sommes toujours dans le Pays de Zayan, une tribu célèbre dans l'histoire du Maroc, population très fière et très accueillante; nous suivons un sentier qui traverse les forêts de cèdres centenaires. Ces forêts abritent quelques dizaines de couples de singes, que nous approchons facilement. Nombreuses bergeries à proximité des pâturages, vue superbe sur la montagne et la cédraie d' Ighoud. Nuit au bord du grand lac Aziza en bivouac`,
      jourImg: "/assets/img//mAtlas/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle:
        "Jour 5: Lac AZIGZA 13OO m ---- Kasbah d'Ait Atmane- 6h de marche. Dénivelé faible",
      jourDesc: `Nous poursuivons notre randonnée à travers la forêt de cèdres, sur un sentier muletier facile. Cette forêt a abrité les lions de l'atlas, ainsi que des tigres, disparus vers les années 7O. Nous suivons une vallée qui nous conduit vers les hauts plateaux arides de la tribut Zayanes. Nuit en Bivouac au bord de la rivière.
`,
      jourImg: "/assets/img//mAtlas/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle:
        "Jour 6: Ait Atmane ----Lac Ouiouane. 5H de marche. Dénivelé faible.",
      jourDesc: `Nous traversons des terres agricoles et des cultures en terrasses à travers des forêts de chaine vert. Nous montons pour atteindre le col qui nous mène au lac Ouiouane avec de très beaux paysages. Nuit dans un gite luxueux au bord du lac.`,
      jourImg: "/assets/img//mAtlas/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "Jour 7: Retour à Marrakech ou à Fez. Nuit à l'hôtel",
      jourDesc: ``,
      jourImg: "/assets/img//mAtlas/7.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "Jour 8: Transfert à l'aéroport selon l'heure du vol.",
      jourDesc: ``,
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: false,
    },
  ];
  const Trek_berber_amzmize = [
    {
      jour: "1",
      jourTitle: "1 ème jour: Marrakech -ait zitoun",
      jourDesc: `Départ vers 9h de Marrakech vers  le barrage de Marrakech en voiture jusqu’à un petit village de tachbibte pour rencontrer votre muletier et derection Ait Zitoun. 5 h de marche le déjeuner sur la route . Nuit chez l’habitant`,
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: "2 ème jour : ait zitoun -ait Ahmed",
      jourDesc: `Départ matinal vers  le village d’ait Ahmed en traversant des cultures en terrasse et les montagnes de haute Atlas avec la traverseé du col d’ait Ahmed 1450 m 6h de marche le déjeuner sur la route .nuit chez l’habitant
`,
      jourImg: "/assets/img/Berber/4.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "3 ème jours : ait Ahmed - Imi ntala",
      jourDesc: `Départ vers la vallée d’Amzmiz et traverseé des superbes et magnifiques villages berbère les cultures en terrasse avant d’arriver dans le beau village dimi ntala .Déjeuner sur place et visiter du village l’après midi . Nuit chez l’habitant (possibilité de faire un hammam .5 de marche
`,
      jourImg: "/assets/img/Berber/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "4 ème jours : une boucle imi nta la -tnerte imi ntala",
      jourDesc: `Départ le matin vers le grand canyon dimi ntala direction tnerte en traversant aussi une forêt de pain d’Alep avec les contrastes des couleurs .déjeuner à Tnerte et retour vers I’m in tala . Nuit au même village 6h de marche`,
      jourImg: "/assets/img/Berber/5.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "5 ème jours : imi ntala Marrakech",
      jourDesc: `Une marche matinal vers Amzmiz 4h de marche tranquille sur la route qui amène vers Amzmiz déjeuner sur la route avant de reprendre le transport pour retourner à Marrakech`,
      jourImg: "/assets/img/Berber/7.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "6 ème: Transfert à l'aéroport selon l'heure du vol.",
      jourDesc: ``,
      jourImg: "/assets/img/berberCard.jpg",
      reverce: false,
    },
  ]
  // const Trek_atlantique = [
  //   {
  //     jour: "1",
  //     jourTitle: "",
  //     jourDesc: ``,
  //     jourImg: "/assets/img/",
  //     reverce: true,
  //   },
  //   {
  //     jour: "2",
  //     jourTitle: "",
  //     jourDesc: ``,
  //     jourImg: "/assets/img/",
  //     reverce: false,
  //   },
  //   {
  //     jour: "3",
  //     jourTitle: "",
  //     jourDesc: ``,
  //     jourImg: "/assets/img/",
  //     reverce: true,
  //   },
  //   {
  //     jour: "4",
  //     jourTitle: "",
  //     jourDesc: ``,
  //     jourImg: "/assets/img/",
  //     reverce: false,
  //   },
  //   {
  //     jour: "5",
  //     jourTitle: "",
  //     jourDesc: ``,
  //     jourImg: "/assets/img/",
  //     reverce: true,
  //   },
  //   {
  //     jour: "6",
  //     jourTitle: "",
  //     jourDesc: ``,
  //     jourImg: "/assets/img/",
  //     reverce: false,
  //   },
  //   {
  //     jour: "7",
  //     jourTitle: "",
  //     jourDesc: ``,
  //     jourImg: "/assets/img/",
  //     reverce: true,
  //   },
  //   {
  //     jour: "8",
  //     jourTitle: "",
  //     jourDesc: ``,
  //     jourImg: "/assets/img/",
  //     reverce: false,
  //   },
  // ];

  const Le_Saghro_8J = [
    {
      jour: "1",
      jourTitle:
        "1 jour: arrivée à l'aéroport de Marrakech, accueil et transfert à votre hôtel.",
      jourDesc: ``,
      jourImg: "/assets/img/berberCard.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: "2 jour:",
      jourDesc: `Départ pour le sud en traversant les plaines de Marrakech par la vallée de Ait ourir, puis la traversée du haut atlas par le col de Tizi Tichka à 2260m d'altitude avec plusieurs points de vue panoramique. Déjeuner à Ouarzazate puis départ pour la palmeraie de Draa que nous quittons pour le village de Handour (1500m d'altitude) le point de départ de notre trek. Nuit au gite. 6H de route.`,
      jourImg: "/assets/img/Saghro/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "3 jour: ",
      jourDesc: `Notre trek commence par plusieurs oasis d'amandier, de laurier roses, de palmier au milieu de jardins en terrasses verdoyants dans un cadre désertique. Nous remontons la vallée Handour à travers des gorges qui s'ouvre sur l'oasis de Tifdassine puis celle de Tasgdilte. Pique nique dans une oasis à la sortie des gorges et montée dans l'après midi vers le site de Bab n'Ali (deux pitons rocheux qui s'élève à plus de 80m dans un décors minéral exceptionnel). Bivouac à Bab n'Ali. 7H de marche.`,
      jourImg: "/assets/img/Saghro/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "4 jour:",
      jourDesc: `Notre journée de marche commence par la découverte des gorges d'Afourar. Un passage dans des gorges magnifiques avec des falaises qui s'élève à plus de 60 m. des forêts de lauriers roses, de soul, lauriers roses, gotte-fière, amandiers......Nous croisons pendant notre marche des villageois qui sont au travail dans leurs jardins, des bergers et des bergères qui viennent à notre rencontre.....Pique nique à la sortie de la vallée d'Afourar. Dans l'après midi, nous prenons un peu d'altitude pour rejoindre le village perdu de Igli dans un décor minéral du hoggar entouré de falaises et de tours de grès. Nuit chez l'habitant à Igli 1800m d'altitude. 5H de marche.`,
      jourImg: "/assets/img/Saghro/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "5 jour: ",
      jourDesc: `Longue montée en direction de la haute falaise qui domine le village. Puis traversée d'un plateau aride à 2300m d'altitude où les bergers d'Ait Atta y installent leurs tentes nomade. Pique nique avant d'attaquer une deuxième montée pour faire le sommet de Koua ouch à 2600 m pour ceux qui le souhaitent. Descente sur le plateau d' Almou N'ouareg à 2300m d'altitude. Bivouac. 6H de marche.`,
      jourImg: "/assets/img/Saghro/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "6 jour:",
      jourDesc: `Nous quittons les bergeries d'Almou n'Ouareg par une longue montée sans difficulté physique jusqu'au col de Tagdilte avec une très belle vue sur la vallée des roses, vallée Dadès et le massif de Mgoun. Descente à la vallée de Tagdilte (1800m d'altitude). Nuit dans un gite luxueux. 6H de marche.`,
      jourImg: "/assets/img/valle_de_rose/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "7 jour:",
      jourDesc: ` Retour à Marrakech par la route des kasbahs de la vallée des roses, palmeraie de Skoura, Ouarzazate où nous prenons le déjeuner et en fin d'après midi arrivée à Marrakech et nuit à l'hôtel. 6H de route.`,
      jourImg: "/assets/img/valle_de_rose/2.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "8 jour:Fin de Nous transfert à l'aéroport.",
      jourDesc: ``,
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: false,
    },
  ];

  const Rif_Chefchaoune = [
    {
      jour: "1",
      jourTitle:
        "1 jour : Arrivée à l'aéroport de Tanger, Accueil par notre guide et nuit à l'hôtel.",
      jourDesc: ``,
      jourImg: "/assets/img/Rif/1.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: "2 jour : ",
      jourDesc: ` Transfert à Chefchaoune 112 km pour 4 h de route, visite de la médina et nuit dans une maison d’hôte: Dar Baibou à Chefchaouen`,
      jourImg: "/assets/img//Rif/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "3 jour : Chefchaouen – Khizana",
      jourDesc: `Visite guidée de la ville de Chefchaouen, la Kasbah, la Medina. Déjeuner et Transfert vers Bab Taza dans l’après-midi et arrêt pour une première rando entre Bab Taza et Khizana. Dîner et logement au gîte de Khizana.`,
      jourImg: "/assets/img//Rif/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "4 jour : Khizana – Mechkralla (6h-7h - dénivelé 600m)",
      jourDesc: ` Marche dans la forêt de chêne liège et son cortège floristique (arbousier, lentisque, bruyère...). Traversée du Douar bniZid puis de l’oued ; remontée par les champs en terrasses en direction de Magou puis Mechkralla. Dîner et logement en gîte.`,
      jourImg: "/assets/img//Rif/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "5 jour : Mechkralla - Azilane (6h - dénivelé 800 m)",
      jourDesc: ` Remontée en direction de Tissouka jusqu’au col du même nom (1750 m).
Sur l’autre versant, nous arrivons dans la fameuse forêt de sapin (Abies pinsapo) et de cèdre (Cedrus atlantica). Nous descendrons le long du chemin qui mène à Azilane (1370 m). Dîner et logement en gîte.`,
      jourImg: "/assets/img//Rif/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "6 jour : Azilane – Akchour : (6h - dénivelé 400m)",
      jourDesc: `Nous descendrons par la vallée jusqu’au village d’Imizzar où nous traverserons la rivière Farda. Puis le sentier suivra la rivière. Possibilité de rencontrer des singes le long de notre chemin. Finalement, nous arriverons au petit réservoir de Akchour puis au gîte. Dîner et logement en gîte.`,
      jourImg: "/assets/img//Rif/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "7 jour : Akchour - Beni Maala (6h - dénivelé 700m)",
      jourDesc: `D’Akchour nous commencerons notre marche en direction du village de Talembote. De là, l’itinéraire passe à travers une zone agricole, entre les villages de Tajiniarte, Tamsasnoute et Beni Malaa. Dîner et logement en gîte`,
      jourImg: "/assets/img//Rif/7.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle:
        "8 jour : Beni Maala - Oued Laou – Tanger (105 km pour 2h de route) et (5 h de marche- dénivelé 400m)",
      jourDesc: `Durant cette étape, nous traverserons la forêt d’Achacha . Puis, tranquillement nous passerons par des paysages agricoles donnant sur la rivière Laou. Après avoir franchi la rivière, nous arriverons au village d’Oued Laou, petit centre touristique donnant sur la mer méditerranée. Après le déjeuner, transfert à Tanger en bus (2h). Hébergement en hôtel 3*. Dîner et nuit.`,
      jourImg: "/assets/img//Rif/8.jpg",
      reverce: false,
    },
    {
      jour: "9",
      jourTitle: "9 jour : Fin De Nous Servise transfert à l'aéroport.",
      jourDesc: ``,
      jourImg: "/assets/img//Rif/9.jpg",
      reverce: true,
    },
  ];
  const vallées_de_Toubkal_8J = [
    {
      jour: "1",
      jourTitle: "1 jour : Arrivée à Marrakech, transfert et nuit à l'hôtel.",
      jourDesc: ``,
      jourImg: "/assets/img/berberCard.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: "2 jour : ",
      jourDesc: ` Départ de Marrakech pour la vallée d'Asni que nous longeons jusqu'au village de Imi Oughlad 1600m, où nous rencontrons notre équipe muletière. Nous traversons le village et ses jardins en terrasses et ses jardins en terrasse afin de commencer une montée régulière vers le col de Tacht à 2100m. Magnifique vallée rouge d'Oussartek et des villages en pierres accrochés aux falaises. Nuit au gîte de Tizi Oussem à1750m.6h de marche`,
      jourImg: "/assets/img/vallées_de_Toubkal/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "3 jour :",
      jourDesc: `Par les bergeries de Tamsoulte, nous montons une foret de genévrier pour le col de M'zik à 2000m, belle vue sur le village d'Imlil où nous prenons un pique nique sous les noyers centenaires. Nous rejoignons après le village d'Armed. Bivouac.5 à 6h de marche`,
      jourImg: "/assets/img/toubkal/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "4 jour : ",
      jourDesc: `nous quittons la vallée d'Armed pour la vallée de Tajdirte par le col de tizi n'Tamatarte à 2300m. Très belle vue panoramique sur les deux vallées. Descente de la vallée à l'ombre des noyers jusqu'au village d'Imskar. Nuit en gîte chez une famille berbère.5 à 6h de marche`,
      jourImg: "/assets/img/toubkal/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "5 jour : ",
      jourDesc: `Une longue montée régulière à travers les forêts de chêne vert en traversant plusieurs villages berbères : tissili, Tagadirte ..., nous arrivons en fin à la station d'Oukaimden située à plus de 2600m d'altitude. Nuit en bivouac.6h de marche.`,
      jourImg: "/assets/img/toubkal/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "6 jour : ",
      jourDesc: ` Nous commençons notre journéepar un sentier qui offre une vue panoramique sur la vallée de l'ourika et les plaines de Marrakech, par les pâturages de tifarguine nous remontons vers le col d'ouatar (2900m) puis descente vers le village d'Agouns. Nuit en bivouac.6 h de marche`,
      jourImg: "/assets/img/toubkal/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "7 jour : ",
      jourDesc: ` Nous quittons le village d'Agouns en traversant de nombreux villages berbères accrochés aux falaises le long de la rivière D'ourika et à l'ombre des noyers. Nous sommes en fin au cour de la vallée. Pique nique près des cascades de Setti fatma et retour à Marrakech. Nuit à l'hôtel.4 à 5 heure de marche.`,
      jourImg: "/assets/img/toubkal/7.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "8 jour : Fin de Nous Service  transfert à l'aéroport.",
      jourDesc: ``,
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: false,
    },
  ];

  const Toubkal_15J = [
    {
      jour: "1",
      jourTitle:
        "1 jour : Arrivée à l'aéroport de Marrakech, transfert à l'hôtel.",
      jourDesc: ``,
      jourImg: "/assets/img/berberCard.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: "2 jour :",
      jourDesc: `Une petite journée tranquille pour un premier contact avec la montagne. Transfert en mini bus pour asni puis le village d'imi Oughlad. Petite marche et nuit chez l'habitant dans un gîte au village d'imskar à 1500m d'altitude,1h de marche`,
      jourImg: "/assets/img/toubkal/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "3 jour: ",
      jourDesc: `Par un setier escarpé au milieu des genévrier nous remontons la vallée pour atteindre le village de Tagadirte puis la station d'Oukaimden à 2600m. Bivouac. 6H de marche`,
      jourImg: "/assets/img/toubkal/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "4 jour : ",
      jourDesc: `Visite des gravures rupestre de la station et montée régulière pour le col de tizi n'Addi 3100m puis descente vers le village de Tajdirte. Bivouac près de la grande source. 4H de marche`,
      jourImg: "/assets/img/toubkal/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "5 jour : ",
      jourDesc: ` Longue montée pour faire le col de tizi likemte à 3500 m. très belle vue sur les vallées de Toubkal. Descente sur la rivière likemte. Bivouac 6h de marche`,
      jourImg: "/assets/img/toubkal/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "6 jour :",
      jourDesc: ` Nous montons la vallée likemte le long de la rivère jusqu'au col de tizi n 'Ouaray 3100m, Très belle vue sur le sommet de toubkal juste en face et sur la vallée d'Amsouzar. Descente et nuit dans un gîte au village d'Amsouzar,6 h de marche`,
      jourImg: "/assets/img/toubkal/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "7 jour :",
      jourDesc: `On va à dos de mules faire le souk (le marché hebdomadaire) avec les locaux. Des tagines seront servis pour le repas de midi dans un restaurant local et retour au gîte en fin de journée.`,
      jourImg: "/assets/img/toubkal/7.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "8 jour : ",
      jourDesc: `Nous quittons la vallée pour attaquer la montagne de Toubkal, nuit rn bivouac au bord du lac Ifni à 2300m d'altitude.4 h de marche`,
      jourImg: "/assets/img/Berber/2.jpg",
      reverce: false,
    },
    {
      jour: "9",
      jourTitle: "9 jour : ",
      jourDesc: ` Longue montée pour faire le col de tizi wanoums 3650m puis descente au refuge de toubkal à 3200m. Bivouac6h de marche`,
      jourImg: "/assets/img/Berber/3.jpg",
      reverce: true,
    },
    {
      jour: "10",
      jourTitle:
        " Longue montée pour faire le col de tizi wanoums 3650m puis descente au refuge de toubkal à 3200m. Bivouac6h de marche",
      jourDesc: ``,
      jourImg: "/assets/img/Berber/4.jpg",
      reverce: false,
    },
    {
      jour: "11",
      jourTitle: "11 jour :",
      jourDesc: ` Nous remontons à nouveau pour un autre col, col Aguelzim à plus de 3400m puis descente sur les cascades de tamsoulte. Bivouac 6h de marche`,
      jourImg: "/assets/img/Berber/5.jpg",
      reverce: true,
    },
    {
      jour: "12",
      jourTitle: "12 jour : ",
      jourDesc: `Nous retrouvons les villages et les vallées de nouveau, traversée du village de tizi oussem et ses belles terrasses verdoyantes. Bivouac 5h de marche`,
      jourImg: "/assets/img/Berber/6.jpg",
      reverce: false,
    },
    {
      jour: "13",
      jourTitle: "13 jour:",
      jourDesc: ` Par le col de tizi Tachte 2100m, nous rejoignons le village d'imi oughlad et retour à Marrakech 3h de marche`,
      jourImg: "/assets/img/Berber/7.jpg",
      reverce: true,
    },
    {
      jour: "14",
      jourTitle: "14 jour :Journée libre à Marrakech",
      jourDesc: ``,
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: false,
    },
    {
      jour: "15",
      jourTitle: "15 jour :Fin De Nous Service Transfert à L'aéroport",
      jourDesc: ``,
      jourImg: "/assets/img/valle_de_rose/6.jpg",
      reverce: true,
    },
  ];
  const Le_grand_sud = [
    {
      jour: "",
      jourTitle: "Grand Sud en VTT:",
      jourDesc: `Circui Vtt dans le grand Sud: Ouarzazate -Jbel Saghro -Oasis de Tazarine - Oasis Ramlia - Zagora et palmeraie de Draa.
Pendant 5 jours et demi, notre itinéraire vous permettra de découvrir les plus belles pistes du grand sud Marocain. De la traversée du Jbel Saghro à la découverte des Oasis verdoyantes du sud. De la Kasbahs dont celle d' Ait Ben Haddou à la vallée des  Kasbah et la palmeraie de Skoura. Traversée de Jbel Saghro, une montagne volcanique avec des tours et de belles gorges, des oasis de lauriers roses et d'amandier. De l'Oasis de Nkob à la palmeraie de Tzarine puis traversée de la vallée de Draa avec ses palmeraies et ses dunes dorées. Enfin longue journée entre dunes et palmeraies dans un décors magnifique du Hogar à Zagora.`,
      jourImg: "/assets/img/grandSud/2.jpg",
      reverce: false,
    },
    {
      jour: "",
      jourTitle: "Programme en Bref:",
      jourDesc: `*Marrakech - Tichka - Vallée Taouate - Ait Ben hadou.
Cette première journée souvent réservée au transfert. On va essayer quand m^me de pédaler un peu de ait Ben Haddou à Ouarzazate sur 20 km de piste.
-->Distance: 200 km dont 20 en VTT. Dénivelé faible.
*Petit transfert à Skoura - Traversée de Jbel Saghro - Tagmoute - Nkob.
-->Distance: plus de 80 km. Dénivelé positif: 1500m et négatif 900m.
*Nkob - Tanoumrite - Zaalou - Ait Ouaazik - Tazarine -Camp Sardrar
-->Distance: 54 km. Dénivelé positif 500m et négatif 250m.
*Tazarine - oasis Tifakhsite - Talhajate - maadir - M'harch - Ramlia
-->Distance: plus de 80 km. Dénivelé faible.
*Ramlia - Tismoumine - Zagora.
-->Distance:78 km. Dénivelé: faible
*Zagora - Oarzazate - Marrakech (360km en 4x4)
Nos services
-->Tous les transferts en voitures 4x4 avec chauffeurs expérimentés
--> des voitures 4x4 d'assistance pendant tout le circuit (nombre de voiture dépend du nombre de participants)
-->guide diplômé souvent moi m^me pour le bon déroulement du circuit (je pédale avec mes clients)
-->de 5 à 6 jours de Vtt selon le programme et selon votre disponibilité
-->tous les repas en pension complète. 
-->Des nuits hôtel, maison d'hôte, Riad, camping, pendant le circuit en demi pension et pour les midi, des piques-niques inclus dans le forfait.(douche chaude tous les soirs)`,
      jourImg: "/assets/img/berberCard.jpg",
      reverce: true,
    },

    {
      jour: "",
      jourTitle: "Prix:",
      jourDesc: `* A partir de 500 euros par personne pour les groupes de 8 participants minimum
*Pour les groupes de 4 à 7 personnes: le prix est calculé selon le nombre de participants (nous consulter).`,
      jourImg: "/assets/img/grandSud/3.jpg",
      reverce: true,
    },
    {
      jour: "",
      jourTitle: "Non compris:",
      jourDesc: `*Vol, chacun doit prendre son billet de vol aller retour avec la compagnie aérienne de son choix
*Assurance voyage (assistance médicale et rapatriement)
*eau minérale et boisson
*Les VTT: il est souhaitable que chaque participant amène sont propre Vtt sinon comptez 30 euros par jour pour la location d'un Vtt haute gamme avec ou sans double suspension.
 * Programme détaillé est à votre disposition sur simple demande par mail.`,
      jourImg: "/assets/img/grandSud/4.jpg",
      reverce: false,
    },
  ];
  const Circuit_4x4_Haut_atlas = [
    {
      jour: "1",
      jourTitle:
        "jour 1 : arrivée à l'aéroport de Marrakech, transfert et nuit à l'hôtel.",
      jourDesc: ``,
      jourImg: "/assets/img/berberCard.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle: " jour 2: Par les champs d'oliviers de la plaine de Marrakech",
      jourDesc: `, nous attaquons les montagnes du haut atlas central, visite des cascades d'Ouzoudes où nous servons un thé.Par la ville d'Azilal, nous rejoignons la vallée heureuse des Ait bougmez.Nuit chez l'habitant.`,
      jourImg: "/assets/img/Circuit_4x4_Haut_atlas/2.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle: "jour 3:",
      jourDesc: `Nous visitons plusieurs villages et ses belles Kasbahs en pisé en longeons les champs de la luzernes et à l'ombre de noyers centenaires.Un repas sera servi chez l'habitant.Puis nous montons au grenier collectif de Sidi moussa avec une belle vue sur toute la vallée.Nous quittons dans l'après midi la vallée pour le village de zaouiat ahansal. nuit au gite.`,
      jourImg: "/assets/img/Circuit_4x4_Haut_atlas/3.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle: "jour 4:",
      jourDesc: `Nous longeons la vallée en direction d'anargui, puis remontons vers les hauts plateaux arides des ait soukhmane et à ait hdidou. Nous arrivons en fin de journée au lac de tislite (2200m).bivouac`,
      jourImg: "/assets/img/Circuit_4x4_Haut_atlas/4.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "jour 5:",
      jourDesc: `Après le village d'imilchil, nous parcourons des pistes perdues dans les montagne et les forêts du massif Maaskar (100km) . Possibilité d'aperce voir des singes. Très beaux paysages sauvages. Région peu connue et rarement parcourue. Nuit à Tounfite.`,
      jourImg: "/assets/img/cardMoyenAtlas.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle:
        "jour 6: Journée dans les forêts et lacs du moyen atlas. Nuit chez l'habitant dans la région de Khénifra. Un repas traditionnel au menu.",
      jourDesc: ``,
      jourImg: "/assets/img/mAtlas/4.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "jour 7: Retour à Marrakech.",
      jourDesc: ``,
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "jour 8: Transfert à l'aéroport.",
      jourDesc: ``,
      jourImg: "/assets/img/valle_de_rose/6.jpg",
      reverce: false,
    },
  ];
  const Grand_sud_en_4x4 = [
    {
      jour: "1",
      jourTitle:
        "1 jour : Envol pour Marrakech, transfert et nuit à l'hôtel en BB.",
      jourDesc: ``,
      jourImg: "/assets/img/berberCard.jpg",
      reverce: true,
    },
    {
      jour: "2",
      jourTitle:
        "2 jour : Départ matinal pour le versant sud par le col de Tichka à 2260m.",
      jourDesc: ` Descente sur la vallée de Talouate et visite de la Kasbah d'ait ben Haddou. Nuit à l'hôtel à ouarzazate.`,
      jourImg: "/assets/img/valle_de_rose/5.jpg",
      reverce: false,
    },
    {
      jour: "3",
      jourTitle:
        "3 jour : Nous longeons la route des mille Kasbah, pour arriver à la vallée des roses",
      jourDesc: `Après la traversée de la vallée des roses par le village de Boutaghrar, nous arrivons dans la vallée de Dadès, visite des gorges et nuit chez l'habitant.`,
      jourImg: "/assets/img/valroseCar.jpg",
      reverce: true,
    },
    {
      jour: "4",
      jourTitle:
        "4 jour : Direction le Jbel Saghro et ses montagnes volcaniques",
      jourDesc: `ses oasis d'amandiers et de lauriers roses. Balades à pied vers les tours du site de Bab n'Ali. Nuit en bivouac sous les tentes à Bab n'Ali.`,
      jourImg: "/assets/img/CardSaghro.jpg",
      reverce: false,
    },
    {
      jour: "5",
      jourTitle: "5 jour : Après le Saghro",
      jourDesc: ` nous arrivons au village de Nkob, puis la palmeraie de Tazarine. Par l'oasis d'Ait Ouazik et ces pâturages où les nomades des ait Atta s'installent avec leurs troupeaux de chèvres et dromadaires, nous arrivons au col de tizi n'Tafilalte. Nous longeons ensuite les palmeraies de Draa jusqu'au village d'Oulad Driss. Nuit sous tentes nomades dans un camping.`,
      jourImg: "/assets/img/Grand_sud_en_4x4/5.jpg",
      reverce: true,
    },
    {
      jour: "6",
      jourTitle: "6 jour : ",
      jourDesc: ` Nous organisons une balade à dos de dromadaires dans les dunes et la palmeraie d'Oulad Driss, puis départ en 4/4 dans une mer de dunes pour les grandes dunes de Ch'Gaga. Nuit en bivouac dans les dunes.`,
      jourImg: "/assets/img/Grand_sud_en_4x4/6.jpg",
      reverce: false,
    },
    {
      jour: "7",
      jourTitle: "7 jour : ",
      jourDesc: ` Après le lever du soleil, Nous longeons le grand lac sec d'Iriki entre dunes et montagne de Jbel Bani dans un décor de western exceptionnel. Par la ville de Foum Zguide, nous reprenons la route pour Marrakech.nuit à l'hôtel.`,
      jourImg: "/assets/img/Grand_sud_en_4x4/7.jpg",
      reverce: true,
    },
    {
      jour: "8",
      jourTitle: "8 jour : transfert à l'aéroport selon les heures du vol.",
      jourDesc: ``,
      jourImg: "/assets/img/Marrakech.jpg",
      reverce: false,
    },
  ];
  const Traversee_du_Désert = [
    {
      jour: "",
      jourTitle: "Marrakech - Ouarazate - Tinghir - Arfoud - Marzoga - Taouz",
      jourDesc: `Transfert de 450 km  ou 250 km selon la ville d'arrivée (marrakech ou ouarzazate) en 4x4`,
      jourImg: "/assets/img/desert/8.jpg",
      reverce: true,
    },
    {
      jour: "",
      jourTitle: "Taouz - Ouzina - Ramlia ",
      jourDesc: ` Distance 60 km. dénivelé faible`,
      jourImg: "/assets/img/Traversee_du_Désert/2.jpg",
      reverce: false,
    },
    {
      jour: "",
      jourTitle:
        "Ramlia - TAfraoute sidi Ali - Omjrane- Tismoumine - Transfert à Zagora",
      jourDesc: `Distance: plus de 80 km. Dénivelé faible.`,
      jourImg: "/assets/img/Traversee_du_Désert/3.jpg",
      reverce: true,
    },
    {
      jour: "",
      jourTitle:
        "Zagora - Beni Ali - Palmeraie Air Amre - Dunes Nasrate - Ksar Zaouiat Sidi Salih - Oulad Driss.",
      jourDesc: `Distance: plus de 60 km. Dénivelé faible.`,
      jourImg: "/assets/img/Traversee_du_Désert/4.jpg",
      reverce: false,
    },
    {
      jour: "",
      jourTitle:
        "Oulad Driss - Erg Lihoudi- Oued Naam- Bougarne-  Oued Latache -Dunes de Chgaga",
      jourDesc: `Distance: plus de 60 km. Dénivelé faible.`,
      jourImg: "/assets/img/Traversee_du_Désert/5.jpg",
      reverce: true,
    },
    {
      jour: "",
      jourTitle:
        "Dunes chgaga- Lac sec d'Iriki- Jbel Bani - Foum Zguite - Taznakhte -Col Tichka - Marrakech.",
      jourDesc: `Distance environ 40 km en Vtt et 400 km ou 200 km en 4x4 (soit à marrakech, soit à ouarzazate)`,
      jourImg: "/assets/img/Traversee_du_Désert/6.jpg",
      reverce: false,
    },
    {
      jour: "",
      jourTitle: "Nos services:",
      jourDesc: `Tous les transferts en voitures 4x4 avec chauffeurs expérimentés
, 2 nuits dans hôtel en demi pension selon la ville de votre arrivée: Marrakech ou Ouarzazate
, des voitures 4x4 d'assistance pendant tout le circuit (nombre de voiture selon le nombre de participants)
,guide diplômé souvent moi même pour le bon déroulement du circuit (je pédale avec mes clients)
,de 4 à 5 jours de Vtt selon le programme et selon votre votre ville d'arrivée.
,tous les repas en pension complète
,Des nuits en gîte, auberge et en bivouac pendant le circuit en demi pension et pour les midi, pique-nique
Prix: A partir de 500 euros par personne pour les groupes de 8 participants minimum. Pour les groupe de 4 à 7 personnes, nous consulter.
,Non compris:
,Vol (chacun doit prendre son billet d'avion aller et retour avec une compagnie aérienne de son choix)
,Assurance voyage (assistance médicale et rapatriement)
,eau minérale et boisson
,Les VTT: il souhaitable que chaque participant amène sont propre Vtt sinon comptez 30 euros par jour pour location de Vtt haute gamme avec ou sans double suspension.
    , Programme détaillé est à votre disposition sur simple demande par mail.`,
      jourImg: "/assets/img/Traversee_du_Désert/7.jpg",
      reverce: true,
    },
  ];
  return (
    <Rtouer>
      <ScrollToTop/>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div className="App">
              <Header />
              <Services />
              <Treks />
              <SurMesure />
              <Testemonial />
              <Aboutus />
              <Contactus />
            </div>
          }
        />
  <Route
          exact
          path="/InfosPratiques"
          element={
           <InfosPratiques />
          }
        />
        <Route
          exact
          path="/Trek_de_la_vallee_des_roses"
          element={
            <PagrTrek 
            TrekValiable={"MARS à fin MAI"}
              TrekTitle={"Trek Vallée des Roses"}
              TrekName={Trek_de_la_vallée_des_roses}
              TrekBckImg={"/assets/img/valroseCar.jpg"}
              prix4={"400"}
              prixP4={"350"}
              children={"250"}
            />
          }
        />
        <Route
          exact
          path="/Trek_desert"
          element={
            <PagrTrek 
            TrekValiable={"OCTOBER à AVRIL"}
              TrekTitle={"Trek de la Vallée du Draa et ksour de Zagora"}
              TrekName={Trek_désert}
              TrekBckImg={"/assets/img/chameu.jpg"}
              prix4={"480"}
              prixP4={"400"}
              children={"350"}
            />
          }
        />
        <Route
          exact
          path="/Trek_berbere"
          element={
            <PagrTrek 
            TrekValiable={"TOUT L'ANNEE"}
              TrekTitle={"Trek des Villages Berbères avec Visite de Marrakech"}
              TrekName={Trek_berbère}
              TrekBckImg={"/assets/img/bcImg.jpg"}
              prix4={"350"}
              prixP4={"300"}
              children={"230"}
            />
          }
        />
        <Route
          exact
          path="/Trek_atlantique"
          element={
            <PagrTrek 
            TrekValiable={"TOUT L'ANNEE"}
              TrekTitle={"Trek côte Atlantique avec la Visite d’Essaouira"}
              TrekName={Trek_atlantique}
              TrekBckImg={"/assets/img/Atlantiqued’EssaouiraCard.jpg"}
              prix4={"380"}
              prixP4={"350"}
              children={"250"}
            />
          }
        />
        <Route
          exact
          path="/Trek_Bouguemmez"
          element={
            <PagrTrek 
            TrekValiable={"AVRIL à SPTEMBER"}
              TrekTitle={"Trek Bouguemmez"}
              TrekName={Trek_Bouguemmez}
              TrekBckImg={"/assets/img/cardBouguemez.jpg"}
              prix4={"400"}
              prixP4={"370"}
              children={"280"}
            />
          }
        />
        <Route
          exact
          path="/Trek_Toubkal"
          element={
            <PagrTrek 
            TrekValiable={"MAI à SPTEMBER"}
              TrekTitle={"Trek Toubkal"}
              TrekName={Trek_Toubkal}
              TrekBckImg={"/assets/img/cardToubqal.jpg"}
              prix4={"380"}
              prixP4={"300"}
              children={"250"}
            />
          }
        />
        <Route
          exact
          path="/Trek_Moyen_Atlas"
          element={
            <PagrTrek 
            TrekValiable={"MARS à MAI"}
              TrekTitle={"Trek Moyen Atlas"}
              TrekName={Trek_Moyen_Atlas}
              TrekBckImg={"/assets/img/cardMoyenAtlas.jpg"}
              prix4={"400"}
              prixP4={"350"}
              children={"250"}
            />
          }
        />
        {/* <Route
          exact
          path="/Circuits_pistes"
          element={
            <PagrTrek 
            TrekValiable={""}
              TrekTitle={"Circuits pistes en4x4"}
              TrekName={Circuits_pistes_en4x4}
              TrekBckImg={"/assets/img/4X4-DESERT.jpg"}
               prix4={"prix4"}
              prixP4={"prixP4"}
              children={"children"}
              />
          }
        /> */}
         <Route
          exact
          path="/Trek_berber_amzmize"
          element={
            <PagrTrek 
            TrekValiable={"TOUT L'ANNEE"}
              TrekTitle={"Trek Berber AMZMIZE"}
              TrekName={Trek_berber_amzmize}
              TrekBckImg={"/assets/img/amzmize.jpg"}
              prix4={"380"}
              prixP4={"280"}
              children={"200"}
            />
          }
        />
        <Route
          exact
          path="/Le_Saghro_8J"
          element={
            <PagrTrek 
            TrekValiable={"NOVEMBER à MAI"}
              TrekTitle={"Le Saghro 8J"}
              TrekName={Le_Saghro_8J}
              TrekBckImg={"/assets/img/CardSaghro.jpg"}
              prix4={"400"}
              prixP4={"350"}
              children={"280"}
            />
          }
        />
        <Route
          exact
          path="/Rif_Chefchaoune"
          element={
            <PagrTrek 
            TrekValiable={"TOUT L'ANNEE"}
              TrekTitle={"Le Rif : Chefchaoune"}
              TrekName={Rif_Chefchaoune}
              TrekBckImg={"/assets/img/Cardchefchaouen.jpg"}
              prix4={"350"}
              prixP4={"300"}
              children={"250"}
            />
          }
        />
        <Route
          exact
          path="/vallees_de_Toubkal_8J"
          element={
            <PagrTrek 
            TrekValiable={"FEVRIER à NOVEMBER"}
              TrekTitle={"3 Vallées de Toubkal 8J"}
              TrekName={vallées_de_Toubkal_8J}
              TrekBckImg={"/assets/img/Card3valeedeTobkal.jpg"}
              prix4={"350"}
              prixP4={"300"}
              children={"250"}
            />
          }
        />
        <Route
          exact
          path="/Toubkal_15J"
          element={
            <PagrTrek 
            TrekValiable={"MAI à OCTOBER"}
              TrekTitle={"Le grand tour de Toubkal 15 j"}
              TrekName={Toubkal_15J}
              TrekBckImg={"/assets/img/cardToubqal.jpg"}
              prix4={"600"}
              prixP4={"500"}
              children={"400"}
            />
          }
        />
        <Route
          exact
          path="/Le_grand_sud"
          element={
            <PagrTrek 
            TrekValiable={"TOUT L'ANNEE"}
              TrekTitle={"Vélo de route le Grand sud"}
              TrekName={Le_grand_sud}
              TrekBckImg={"/assets/img/CardVttGrandSud.jpg"}
              prix4={"500"}
              prixP4={"450"}
              children={"NONE"}
            />
          }
        />
        <Route
          exact
          path="/Circuit_4x4_Haut_atlas"
          element={
            <PagrTrek 
            TrekValiable={"MARS à SEPTEMBER"}
              TrekTitle={"Circuit 4x4 Haut atlas"}
              TrekName={Circuit_4x4_Haut_atlas}
              TrekBckImg={"/assets/img/Card4x4HautAtlas.jpg"}
              prix4={"600"}
              prixP4={"500"}
              children={"400"}
            />
          }
        />
        <Route
          exact
          path="/Grand_sud_en_4x4"
          element={
            <PagrTrek 
            TrekValiable={"TOUT L'ANNEE"}
              TrekTitle={"Grand Sud en 4x4"}
              TrekName={Grand_sud_en_4x4}
              TrekBckImg={"/assets/img/4X4-DESERT.jpg"}
              prix4={"600"}
              prixP4={"500"}
              children={"400"}
            />
          }
        />
        {/* <Route
          exact
          path="/Grand_sud_en_4x4"
          element={
            <PagrTrek 
            TrekValiable={""}
              TrekTitle={"Grand Sud en 4x4"}
              TrekName={Grand_sud_en_4x4}
              TrekBckImg={"/assets/img/4X4-DESERT.jpg"}
              prix4={"prix4"}
              prixP4={"prixP4"}
              children={"children"}
            />
          }
        /> */}
        {/* <Route
          exact
          path="/Traversee_du_Desert"
          element={
            <PagrTrek 
            TrekValiable={"TOUT L 'ANNEE"}
              TrekTitle={
                "Circuit Vtt - Traversée du Désert: Taouz – Zagora- Chgaga"
              }
              TrekName={Traversee_du_Désert}
              TrekBckImg={"/assets/img/CardVTTDesert.jpg"}
              prix4={"prix4"}
              prixP4={"prixP4"}
              children={"children"}
            />
          }
        /> */}
      </Routes>
    </Rtouer>
  );
}

export default App;
