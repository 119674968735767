import React from "react";
import "../services.scss";

const Fire = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className="services__svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2395 5108 c-57 -31 -68 -85 -35 -164 63 -147 80 -332 41 -455 -32
-101 -85 -172 -235 -313 -134 -126 -223 -226 -281 -313 -15 -24 -30 -43 -34
-43 -3 0 -17 33 -30 73 -33 104 -110 258 -180 362 -72 108 -211 269 -254 296
-46 28 -93 25 -126 -8 -27 -28 -27 -30 -39 -233 -21 -355 -63 -527 -204 -845
-148 -333 -174 -449 -165 -748 6 -209 21 -304 78 -475 17 -52 29 -95 27 -97
-2 -2 -37 5 -78 16 -89 23 -231 25 -310 4 -101 -27 -180 -74 -261 -154 -120
-120 -169 -237 -169 -404 0 -167 49 -285 167 -406 40 -41 71 -75 70 -76 -1 -1
-27 -13 -57 -28 -147 -70 -265 -222 -305 -388 -72 -306 128 -621 441 -695 134
-31 145 -29 1167 245 l937 251 428 -114 c235 -63 657 -176 937 -251 562 -151
613 -160 739 -131 116 27 191 71 281 161 59 58 90 99 114 149 108 225 66 485
-106 660 -48 49 -156 126 -177 126 -40 1 -32 20 37 90 118 122 167 240 167
407 0 167 -49 284 -169 404 -81 80 -160 127 -261 154 -79 21 -221 19 -310 -4
-41 -11 -76 -19 -77 -17 -2 2 10 45 27 95 34 106 66 254 76 357 6 69 5 72 -24
105 -40 44 -98 47 -138 7 -23 -22 -29 -43 -45 -150 -21 -136 -62 -280 -116
-404 l-35 -82 -177 -47 c-97 -26 -206 -55 -243 -65 l-68 -19 1 117 c0 150 -10
196 -81 367 -62 148 -90 257 -91 352 -1 101 -14 154 -43 180 -32 27 -85 30
-121 7 -37 -24 -128 -141 -169 -216 l-37 -66 -87 90 c-48 49 -92 101 -99 116
-19 41 -15 122 8 188 17 49 18 65 10 95 -22 73 -86 87 -179 40 -120 -61 -237
-167 -296 -271 -24 -42 -26 -43 -26 -17 0 40 -24 81 -55 95 -68 31 -103 9
-190 -122 -195 -293 -266 -508 -266 -807 l0 -147 -127 34 c-70 19 -179 48
-243 65 l-116 31 -41 96 c-87 206 -122 379 -122 603 0 232 25 330 160 632 125
282 171 453 205 761 l7 59 55 -84 c110 -169 177 -336 215 -537 12 -65 28 -127
36 -139 21 -34 73 -47 115 -30 29 12 43 32 91 121 106 202 215 341 378 484
199 175 282 318 308 527 9 73 8 123 -6 213 l-5 34 48 -33 c71 -49 206 -183
262 -258 144 -197 209 -396 218 -673 6 -183 -4 -295 -41 -448 -25 -105 -22
-143 17 -172 12 -10 40 -18 63 -18 37 0 46 6 87 49 105 113 237 349 297 531
29 89 60 230 77 352 l8 58 58 -98 c79 -133 178 -337 221 -457 20 -55 44 -119
52 -143 33 -89 149 -96 184 -11 16 37 7 77 -45 218 -93 250 -233 504 -440 801
-115 163 -143 182 -214 145 -49 -25 -56 -59 -36 -190 30 -200 12 -410 -53
-600 l-27 -80 -6 115 c-30 516 -312 935 -791 1176 -90 45 -119 51 -153 32z
m134 -2337 c13 -25 61 -82 106 -127 110 -108 160 -206 180 -358 9 -66 48 -106
103 -106 40 0 91 34 93 62 0 7 2 60 4 118 2 74 9 126 25 175 l22 70 33 -100
c18 -55 49 -135 68 -177 49 -105 58 -144 58 -252 0 -73 -13 -175 -25 -193 -1
-1 -144 -40 -319 -87 l-317 -85 -317 85 c-175 47 -318 86 -319 87 -13 21 -25
128 -25 215 0 163 22 274 83 420 17 41 34 70 38 65 4 -4 13 -26 20 -48 19 -59
67 -152 113 -218 66 -95 124 -112 183 -53 38 38 40 51 19 133 -20 77 -20 244
1 314 20 68 55 137 90 178 l27 33 17 -54 c9 -29 27 -73 39 -97z m-1339 -900
c223 -59 529 -141 680 -181 151 -40 278 -76 283 -80 4 -3 -111 -38 -256 -77
l-264 -70 -368 98 c-202 55 -378 99 -391 99 -13 0 -38 -13 -56 -29 -27 -24
-33 -36 -33 -71 0 -71 28 -87 253 -145 105 -27 192 -52 192 -55 0 -3 -118 -37
-262 -76 l-263 -70 -72 22 c-129 39 -203 97 -260 206 -26 49 -28 62 -28 163 0
101 2 114 28 163 73 138 189 211 337 211 65 1 132 -14 480 -108z m3378 75 c75
-38 133 -95 173 -168 33 -61 34 -67 34 -173 0 -106 -1 -112 -34 -173 -40 -74
-104 -135 -174 -167 -29 -13 -236 -74 -462 -134 -225 -60 -916 -245 -1535
-411 -619 -166 -1199 -321 -1289 -346 -91 -24 -166 -42 -168 -40 -2 2 5 32 17
66 74 220 -4 487 -184 629 -25 20 -34 32 -25 35 8 3 520 141 1139 306 1638
439 1854 497 2070 556 182 50 201 53 290 51 81 -3 103 -7 148 -31z m-3823
-1025 c76 -37 147 -111 183 -189 37 -79 38 -208 3 -297 -33 -82 -124 -173
-206 -205 -84 -34 -206 -34 -290 0 -82 32 -173 123 -205 205 -33 83 -34 206
-1 287 40 102 143 194 250 225 77 22 192 10 266 -26z m3960 0 c80 -39 155
-120 186 -199 33 -81 32 -204 -1 -287 -32 -82 -123 -173 -205 -205 -83 -33
-206 -34 -287 -1 -81 32 -160 106 -200 189 -30 61 -33 75 -33 162 0 82 4 103
27 152 48 103 143 185 247 215 77 22 192 10 266 -26z m-681 -79 c-68 -136 -81
-304 -35 -441 12 -35 20 -65 18 -67 -2 -2 -104 24 -228 57 -123 33 -350 94
-504 135 -154 41 -289 78 -299 82 -14 6 42 25 195 66 118 31 360 97 539 146
179 48 330 88 336 89 7 1 -3 -30 -22 -67z"
        />
        <path
          d="M3620 1499 c-322 -87 -599 -165 -617 -173 -70 -33 -63 -137 11 -172
32 -16 44 -13 651 149 687 184 670 177 670 258 0 34 -6 46 -33 70 -23 20 -42
29 -65 28 -17 -1 -295 -73 -617 -160z"
        />
        <path
          d="M2070 1084 c-190 -51 -390 -105 -445 -120 -120 -33 -145 -53 -145
-118 0 -37 5 -48 31 -70 18 -15 43 -26 60 -26 16 0 233 54 482 121 414 110
454 123 480 150 36 39 36 86 1 128 -22 27 -32 31 -72 30 -26 -1 -202 -43 -392
-95z"
        />
        <path
          d="M4114 3175 c-60 -31 -73 -108 -25 -156 69 -68 187 -5 166 89 -13 63
-84 96 -141 67z"
        />
      </g>
    </svg>
  );
};

export default Fire;
