import React, { useState, useEffect } from "react";
import Card from "./components/Card";
import "./testemonial.scss";

const Testemonial = () => {
	const [slide, SetSlide] = useState(0);
	useEffect(() => {
		const intreval = setInterval(() => {
			SetSlide(slide === Client.length - 4 ? 0 : slide + 1);
		}, 3000);

		return () => {
			clearInterval(intreval);
		};
	}, [slide]);

	
	const Client = [
		{
			id: 1,
			name: "Claire Gamelin",
			star: 5,
			job: "Trek vallée des roses",
			testimon:
				" always responsive to any question I have, they keep me informed and they understand who we are and what we’re trying to do",
			src: "../../assets/img/profil/p.jpg",
		},
		{
			id: 2,
			name: "Felicienne Cotuand",
			star: 5,
			job: "Rif Chefchaoune",
			testimon:
				"Quality is always there. Top of the game in terms of Travel companies.",
			src: "../../assets/img/profil/pp.jpg",
		},
		{
			id: 3,
			name: "Raison Gervais",
			star: 5,
			job: "Trek désert && Trek Berber AMZMIZE",
			testimon:
				"L'organisation c'est faite de manière fluide et sérieuse avec une bonne réactivité de l'agence locale.",
			src: "../../assets/img/profil/ppp.jpg",
		},
		{
			id: 4,
			name: "John Smith",
			star: 5,
			job: "Trek désert",
			testimon:
				"Le circuit proposé est parfait Très bons conseils de l'agence et découverte avec le guide connaissant parfaitement le maroc",
			src: "../../assets/img/profil/pppp.jpg",
		},
		{
			id: 5,
			name: "Royden Loiseau",
			star: 5,
			job: "Trek berbère && Trek Saghro",
			testimon: "Organisation du voyage impeccable : un vrai régal.",
			src: "../../assets/img/profil/ppppp.jpg",
		},
		{
			id: 6,
			name: "Dionisia Lombardo",
			star: 5,
			job: "Trek berbère",
			testimon:
				"Un excellent voyage, très bon accueil et organisation correspondant à notre demande. Bravo.",
			src: "../../assets/img/profil/pppppp.jpg",
		},
		{
			id: 7,
			name: "Connor Morris",
			star: 5,
			job: "Trek atlantique ",
			testimon:
				"L'agent local a été très réactive face à mes demandes, me suis sentie tout à fait en confiance. Elle travaille avec des personnes compétentes, on peut y aller les yeux fermés! ",
			src: "../../assets/img/profil/p.jpg",
		},
		{
			id: 8,
			name: "Charlotte Favreau",
			star: 5,
			job: "Trek atlantique ",
			testimon:
				"Un voyage de découverte apaisant et plein d'authenticité. Merci et à une prochaine, Inchala",
			src: "../../assets/img/profil/pp.jpg",
		},
		{
			id: 9,
			name: "Samantha Roberts",
			star: 5,
			job: "Trek Toubkal",
			testimon:
				"Before we define any approach, we need to define the brands overall goal. We then need to dive.",
			src: "../../assets/img/profil/ppp.jpg",
		},
		{
			id: 10,
			name: "Ryan Fuller",
			star: 5,
			job: "Trek désert",
			testimon:
				"Génial pour un groupe familiale avec enfants, plein de possibilités et de proposition. Un séjour Magique avec une nuit dans le désert.",
			src: "../../assets/img/profil/pppp.jpg",
		},
	];
	return (
		<div className="testemonials" id="testemonials">
			<div className="testeminials__top">
				<h3 className="testeminials__top__miniTitle">Happy Clients</h3>
				<h1 className="testeminials__top__title">Ce que disent nos clients</h1>
				<p className="testeminials__top__para">
					La satisfaction du client est une priorité pour notre entreprise, mais
					ne nous croyez pas sur parole,
					<br />
					Écoutez ce que disent nos clients
				</p>
			</div>
			<div className="testemonials__bottom">
				<div
					className="testemonials__bottom__slider"
					style={{ transform: `translateX(${slide * -20}rem)` }}>
					{Client.map((test) => (
						<Card
							key={test.id}
							img={test.src}
							name={test.name}
							star={test.star}
							job={test.job}
							testimon={test.testimon}
						/>
					))}
				</div>
			</div>
			<div className="testemonials__overlay"></div>
		</div>
	);
};

export default Testemonial;
