import React from "react";
import "./surmesure.scss";

const SurMesure = () => {
  return (
    <div className="SurMesure">
      <div className="SurMesure__left">
        <h1 className="SurMesure__left__title">
          Laisser-Nous Vous Aider à Construire Votre Projet
        </h1>
        <h2 className="SurMesure__left__title2">Voyage Sur Mesure</h2>
        <ul className="SurMesure__left__List">
          <li className="SurMesure__left__listItem">
            Vous avez quelques idées mais ne savez pas comment les organiser,
            les mettre en forme
          </li>
          <li className="SurMesure__left__listItem">
            Une panne d’inspiration ou de temps pour mener vos recherches?
          </li>
        </ul>
        <h3 className="SurMesure__left__para">
          Je suis là pour vous aider à concrétiser votre séjour au Maroc
        </h3>
        <p className="SurMesure__left__desc">
          Idées de séjours, d’itinéraires, de logement, comment optimiser vos
          visites, les bons plans restaurants, hôtels ou maisons d’hôtes, les
          activités possibles – et tout cela en fonction de votre budget et de
          votre profil voyageur. Couple, famille, amis – plutôt routard ou
          confort – programme standard ou personnalisé
        </p>
        <h3 className="SurMesure__left__para">
          N’hésitez pas à me contacter pour réaliser votre projet de séjour ou
          de voyage sur mesure au Maroc
        </h3>
      </div>
      <div className="SurMesure__right">
        <img
          src="/assets/img/surMesureImg1.jpg"
          alt="Women in a hiking geare"
          className="SurMesure__img"
        />
      </div>
    </div>
  );
};

export default SurMesure;
