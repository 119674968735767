import React from "react";
import ".././PageTrek.scss";

const PageTreksFirst = ({ jour, jourTitle, jourDesc, jourImg, reverce }) => {
  const nJour = +jour + 1;
  return (
    <div className={`PageTreksFirst `}>
      <div className={`PageTreksFirst__Para ${reverce && "reverce"}`}>
        <span className="PageTreksFirst__nbr">{jour}</span>
        <h3 className="PageTreksFirst__jourTitle ">{jourTitle}</h3>
        <p className="PageTreksFirst__jourDesc">{jourDesc}</p>
      </div>
      {/* <img src={jourImg} className="PageTreksFirst__img" /> */}
      <div
        style={{ backgroundImage: `url(${jourImg})` }}
        className="PageTreksFirst__img"
      ></div>
    </div>
  );
};

export default PageTreksFirst;
