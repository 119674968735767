import React from "react";
import "./contactus.scss";
import "../logo/Logo.jsx";
import Logo from "../logo/Logo.jsx";

const Contactus = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="contactus" id="Contactus">
      <div className="contactus__top">
        <div className="contactus__logo">
          <Logo />
        </div>
        <p className="contactus__top__desc">
          si vous souhaitez réserver nos services ou si vous avez une question,
          n'hésitez pas à nous contacter ou à remplir le formulaire afin que
          notre représentant puisse vous joindre
        </p>
      </div>
      <div className="contactus__bottom">
        <div className="contactus__left">
          <div className="contactus__topRow">
            <i className="fa-brands fa-facebook-f contactus__icon"></i>
            <div className="contactus__topText">
              <h2 className="contactus__topTitle">FACEBBOK</h2>
              <p className="contactus__info">ALI AITELMAHJOUB</p>
            </div>
          </div>
          <div className="contactus__topRow">
            <i className="fa-brands fa-whatsapp contactus__icon"></i>
            <div className="contactus__topText">
              <h2 className="contactus__topTitle">WHATSSAP</h2>
              <p className="contactus__info">+336-33-68-16-48</p>
            </div>
          </div>
          <div className="contactus__topRow leftSpace">
            <i className="fa-solid fa-envelope contactus__icon"></i>
            <div className="contactus__topText">
              <h2 className="contactus__topTitle">EMAIL</h2>
              <p className="contactus__info">montagnedesertomar@live.com</p>
            </div>
          </div>
        </div>
        <form
          className="contactus__right"
          action="https://formsubmit.co/aitelmahjoubali6@gmail.com"
          method="POST"
        >
          <h2 className="contactus__formTitle">LAISSEZ-NOUS UN MESSAGE</h2>
          <div className="contactus__rightRow">
            <input
              type="text"
              className="contactus__formInput"
              placeholder=" "
              name="name"
              required
            />
            <label htmlFor="" className="contactus__lbl">
              Name
            </label>
          </div>

          <div className="contactus__rightRow">
            <input
              type="email"
              className="contactus__formInput"
              placeholder=" "
              name="email"
              required
            />
            <label htmlFor="" className="contactus__lbl">
              E-mail
            </label>
          </div>

          <div className="contactus__rightTxtareaRow">
            <textarea
              className="contactus__formInput"
              cols="25"
              rows="4"
              placeholder=" "
              name="message"
              required
            ></textarea>
            <label htmlFor="" className="contactus__lbl">
              Message
            </label>
          </div>

          <button
            type="submit"
            className="contactus__formbtn"
            // onClick={(e) => handleSubmit(e)}
          >
            SEND
          </button>
        </form>
      </div>

      <div className="contactus__overlay"></div>
    </div>
  );
};

export default Contactus;
