import React from "react";
import { Link } from "react-router-dom";
import "../trek.scss";

const Trek = ({ title, desc, para, bgi, src }) => {
  return (
    <Link
      to={{pathname:`${src}`,hash:'#PagrTrek__hero'}}
      className="trek"
      style={{ backgroundImage: `url(${bgi})` }}
    >
      <div className="trek__top">
        <h3 className="trek__title">{title}</h3>
      </div>
      <div className="trek__bottom">
        <h2 className="trek__bottomTitle">{title}</h2>
        <div>
          <p className="trek__desc">{desc}</p>
          <p className="trek__desc">{para}</p>
        </div>

        <p className="trek__link">Explorer le Trek</p>
      </div>
    </Link>
  );
};

export default Trek;
