import React from "react";
import "./InfosPratiques.scss";
import { Link } from "react-router-dom";

const InfosPratiques = () => {
	const Infos = [
		{
			id: 1,
			title: "Tarif",
			listItem: [
				`Une réduction de 55 € s 'applique pour les enfants de moins de 12 ans.`,
			],
		},
		{
			id: 2,
			title: "Assurances",
			listItem: [
				`Il est fortement conseillé de souscrire à une assurance multirisques pour effectuer ce voyage (assurance annulation, bagages, assistance-rapatriement, frais médicaux, frais de recherche et secours, etc.).`,
			],
		},
		{
			id: 3,
			title: "Informations sur les participants",
			listItem: [
				`Certain trek est accessible pour les enfants, à partir de 4 ans`,
				`Les enfants de moins de 12 ans bénéficient d'un tarif réduit.`,
			],
		},
		{
			id: 4,
			title: "Formalités d’entrée au Maroc",
			listItem: [
				`Le passeport doit être en cours de validité et valable 6 mois après la date d'arrivée au Maroc, pour les ressortissants européens.`,
			],
		},
	];
	const Hébergement = [
		{
			id: 5,
			title: "Organisation d’un camp",
			listItem: [
				`Vous devez emmener un sac de couchage.`,
				`Nos repas sont préparés avec soin (salade composée le midi et tajines le soir). Possibilité de proposer des repas végétariens, et de s’adapter aux allergies et intolérances alimentaires, nous prévenir avant le départ de préférence.`,
				`Nous fournissons de l’eau minérale en bouteille.`,
				`Vous serez accompagné d'une équipe de trek constituée d'un guide, d'un cuisinier et de des chameliers.`,
			],
		},
	];
	const Equipement_à_prévoir = [
		{
			id: 6,
			title: "Matériel",
			listItem: [
				`Sac à dos, pour les dromadaires afin de prendre votre équipement`,
				`Sac à dos, pour vous afin de porter vos affaires personnelles la journée`,
				`Couverture de sac à dos, imperméable (optionnelle)`,
				`Sac de couchage (confort 10° à 15° pour octobre, mars et avril, 5° à 10° pour novembre et février, 0° à 5° pour décembre et janvier)`,
				`Bâtons de trek`,
				`Lampe frontale, avec batteries de rechange`,
				`Gourde, 1 à 2 L`,
			],
		},
		{
			id: 7,
			title: "Vêtements",
			listItem: [
				`Lunettes de soleil`,
				`Chapeau ou casquette`,
				`Bonnet`,
				`Veste imperméable, respirante avec capuche`,
				`Veste souple polaire`,
				`Chemise à manches longues, anti-humidité`,
				`Pantalon de randonnée`,
				`Chaussures de randonnée, type sandale de sport ouverte (avec une paire de chaussette)`,
				`Gants chauds, de préférence imperméables`,
			],
		},
		{
			id: 8,
			title: "Divers",
			listItem: [
				`Crème solaire, à indice élevé`,
				`Baume à lèvres`,
				`Répulsif pour insectes`,
				`Rouleau de papier toilette`,
				`Affaires de toilette`,
				`Ordonnances`,
				`Petite trousse à pharmacie`,
				`Sacs de tailles variées, pour séparer les affaires et les mettre au sec`,
				`Appareil photo, avec batteries de rechange (optionnel)`,
				`Barres énergétiques (optionnelles)`,
			],
		},
		{
			id: 9,
			title: "Lexique",
			listItem: [`Erg : dunes`, `Reg : désert de pierres`],
		},
	];
	return (
		<div className="InfosPratiques">
			<div className="InfosPratiques__top">
				<h3 className="InfosPratiques__miniTitle">Infos Pratiques</h3>
				<h1 className="InfosPratiques__title">Choses à Savoir</h1>
				<p className="InfosPratiques__topDesc">
					Infos Pratiques Pour Une Bon Experience
				</p>
			</div>
			<div className="InfosPratiques__bottom">
				<div className="InfosPratiques__bottomWrapper">
					<div className="InfosPratiques__bottomRow">
						{Infos.map((info) => (
							<div className="InfosPratiques__Tarif" key={info.id}>
								<h3 className="InfosPratiquesbottom__title">{info.title}</h3>
								<ul className="InfosPratiquesbottom__liste">
									{info.listItem.map((item) => (
										<li className="InfosPratiques__listItem">{item}</li>
									))}
								</ul>
							</div>
						))}
					</div>
					<div className="InfosPratiques__bottomRow ">
						<h2 className="InfosPratiques__bottomRowTitle">Hébergement</h2>

						{Hébergement.map((Hebergement) => (
							<div className="InfosPratiques__Tarif" key={Hebergement.id}>
								<h3 className="InfosPratiquesbottom__title">
									{Hebergement.title}
								</h3>
								<ul className="InfosPratiquesbottom__liste">
									{Hebergement.listItem.map((item) => (
										<li className="InfosPratiques__listItem">{item}</li>
									))}
								</ul>
							</div>
						))}
					</div>
				</div>

				<div className="InfosPratiques__bottomRow">
					<h2 className="InfosPratiques__bottomRowTitle">
						Equipement à prévoir
					</h2>
					<div className="lastBox">
						{Equipement_à_prévoir.map((Equipement) => (
							<div className="InfosPratiques__Tarif" key={Equipement.id}>
								<h3 className="InfosPratiquesbottom__title">
									{Equipement.title}
								</h3>
								<ul className="InfosPratiquesbottom__liste">
									{Equipement.listItem.map((item) => (
										<li className="InfosPratiques__listItem">{item}</li>
									))}
								</ul>
							</div>
						))}
					</div>
				</div>
				<Link to="/" className="footer__icon">
					<i className=" fa-solid fa-house-chimney"></i>
				</Link>
			</div>
		</div>
	);
};

export default InfosPratiques;
