import React from "react";
import { Link } from "react-router-dom";
import "./logo.scss";


const Logo = () => {
  return (
    <Link to="/" className="Logo">
      <h1 className="Logo__container">
        {/* Marrakech<span>Treks</span>
        <Tarbouche /> */}
        <img src="/assets/img/test3logooo.png" alt="" />
      </h1>
    </Link>
  );
};

export default Logo;
