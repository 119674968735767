import React from "react";
import "./aboutus.scss";

const Aboutus = () => {
  return (
    <div className="Aboutus" id="Aboutus">
      <div className="Aboutus__top">
        <h1 className="Aboutus__title">Qui Nous Sommes</h1>
      </div>
      <div className="Aboutus__midle">
        <div className="Aboutus__left">
          
            <div className="Aboutus__left__top">
              {/* <div className="box box1"></div> */}
              <img
                src="/assets/img/ProfileOmar.jpg"
                alt="Profile Image"
                className="Aboutus__left__img"
              />
              {/* <div className="box box2"></div> */}
            </div>
         

          <p className="Aboutus__left__intro">
            Hi, Je suis Omar un jeune marocain qui aime l'aventure, les voyages
            et la découverte de nouveaux lieux et traditions. Je travaille
            actuellement comme guide touristique.
          </p>
        </div>
        <div className="Aboutus__right">
          <p className="Aboutus__right__desc">
          Omar Ait Talate un berbère du Haut Atlas. Pendant mon école primaire dans mon village natal j'ai souvent suivi les groupes de randonneurs qui passaient souvent dans ce petit village. Tout ça m'as donné envie de faire des études et d'apprendre les langues pour pratiquer le métier de guide de montage. Et c''est ce que j' ai réalisé, après mes années d'études à Marrakech. En 2003 après mon concours d'entrée au centre des métiers de la montagne à Azilal, et au bout d'un an de formation, je réalise le rêve de mes 10 ans devenir guide. S'en suit le travail dans différentes agences (visage, burle, atlante, marmara, nouvelle frontière). Presque une vingtaine d'années d'expérience à travers le Maroc entre montage, désert et mer. Fort de ces années de guide je propose mes services à vous amateurs de randonnées. Par un choix multiples de circuits pour une découverte du Maroc pour y découvrir les costumes de ces habitants, ses spécialités culinaires, son architecture et ces  paysages.
          </p>
          <div className="Aboutus__right__bottom">
            <div className="Aboutus__right__box">
              <i className="fa-solid fa-users"></i>
              <h2 className="Aboutus__right__box__statistiq"> +156</h2>
              <h2 className="Aboutus__right__box__title">Clients Satisfaits</h2>
            </div>
            <div className="Aboutus__right__box">
              <i className="fa-solid fa-person-hiking"></i>
              <h2 className="Aboutus__right__box__statistiq">+11</h2>
              <h2 className="Aboutus__right__box__title">
              Années d'expérience
              </h2>
            </div>
            <div className="Aboutus__right__box">
              <i className="fa-solid fa-people-group"></i>
              <h2 className="Aboutus__right__box__statistiq"></h2>
              <h2 className="Aboutus__right__box__title">
                Equipe des experts
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
