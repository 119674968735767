import React from "react";
import "../services.scss";

const Destination = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className="services__svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3705 5109 c-145 -21 -317 -86 -438 -167 -277 -183 -451 -468 -490
-802 l-12 -102 -473 -160 -473 -160 -903 311 c-497 171 -906 311 -909 311 -4
0 -7 -807 -7 -1792 l1 -1793 907 -377 907 -378 818 325 c450 179 823 325 830
325 10 0 1211 -420 1575 -550 l82 -30 0 1819 0 1819 -82 28 c-125 41 -116 29
-102 126 91 604 -326 1163 -930 1247 -89 13 -214 12 -301 0z m281 -300 c265
-47 474 -206 589 -452 56 -118 70 -184 69 -337 -1 -148 -22 -250 -85 -407
-112 -282 -370 -662 -651 -961 l-50 -53 -30 28 c-17 15 -76 80 -132 143 -318
360 -511 688 -593 1010 -37 143 -39 335 -5 453 85 296 315 511 612 572 81 16
198 18 276 4z m-2981 -1129 l660 -228 3 -371 c1 -204 -1 -371 -5 -371 -5 0
-152 79 -328 175 l-319 175 -215 -223 c-118 -122 -279 -287 -358 -367 l-143
-145 0 798 0 798 23 -7 c12 -4 319 -109 682 -234z m1805 34 c0 -9 19 -73 42
-142 73 -221 218 -488 387 -716 l71 -96 0 -475 c0 -261 -1 -475 -3 -475 -2 0
-302 163 -667 363 l-665 363 0 459 0 458 405 138 c223 76 411 138 418 138 6 1
12 -6 12 -15z m2008 -1509 l-3 -415 -239 180 c-131 99 -241 180 -243 180 -3 0
-165 -75 -360 -166 -195 -91 -357 -164 -359 -161 -2 2 -3 136 -2 297 l3 292
120 -111 c66 -61 125 -111 131 -111 23 0 336 345 501 552 179 224 379 533 430
667 18 46 18 42 21 -372 1 -229 1 -604 0 -832z m-3443 317 l290 -159 3 -987
c2 -939 1 -988 -15 -982 -10 4 -318 132 -685 284 l-667 277 -1 468 0 468 383
394 c210 217 387 395 392 395 6 0 141 -71 300 -158z m1271 -694 l664 -363 -2
-275 -3 -274 -660 -263 c-363 -145 -663 -263 -667 -263 -5 0 -8 405 -8 900 0
495 3 900 6 900 3 0 305 -163 670 -362z m1914 -223 l260 -195 0 -455 c0 -250
-3 -455 -6 -455 -6 0 -1138 397 -1191 417 -10 4 -13 69 -13 287 l1 281 337
157 c185 86 340 157 345 157 4 1 124 -87 267 -194z"
        />
        <path
          d="M3763 4525 c-245 -53 -401 -308 -338 -550 34 -128 127 -239 252 -298
64 -30 74 -32 183 -32 109 0 119 2 183 32 80 38 173 122 210 191 96 177 64
399 -77 537 -66 64 -137 102 -223 120 -82 18 -109 18 -190 0z m176 -314 c121
-87 70 -271 -76 -271 -138 0 -203 159 -105 253 51 49 128 57 181 18z"
        />
        <path
          d="M690 1835 l-105 -105 93 -93 92 -92 -90 -90 -90 -90 105 -105 105
-105 90 90 90 90 90 -90 90 -90 105 105 105 104 -85 87 c-47 47 -85 90 -85 95
0 5 39 48 87 96 l88 88 -105 105 -105 105 -90 -90 -90 -90 -90 90 c-49 49 -92
90 -95 90 -3 0 -52 -47 -110 -105z"
        />
      </g>
    </svg>
  );
};

export default Destination;
