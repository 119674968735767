import React from "react";
import "../services.scss";
const Campass = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className="services__svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2475 5017 c-161 -53 -243 -221 -188 -383 20 -59 101 -145 156 -165
37 -13 38 -14 35 -59 l-3 -45 -105 -8 c-478 -36 -967 -265 -1321 -618 -366
-367 -580 -833 -621 -1361 -52 -663 237 -1349 751 -1780 442 -370 1017 -552
1577 -497 808 78 1493 593 1792 1344 175 441 197 924 62 1391 -165 570 -594
1069 -1139 1327 -213 100 -504 179 -723 195 l-103 7 -3 45 c-3 45 -2 46 35 59
57 21 137 106 158 170 23 69 17 169 -14 228 -29 57 -85 111 -139 136 -57 24
-154 31 -207 14z m145 -162 c89 -46 96 -176 13 -230 -60 -40 -156 -16 -189 46
-19 37 -18 109 2 138 39 55 116 76 174 46z m66 -655 c1179 -80 2024 -1157
1819 -2319 -69 -396 -258 -754 -549 -1047 -501 -502 -1221 -696 -1905 -513
-533 142 -976 496 -1239 989 -225 421 -286 936 -167 1406 105 413 349 791 683
1057 306 245 704 401 1085 427 61 4 117 8 122 8 6 1 73 -3 151 -8z"
        />
        <path
          d="M2396 3839 c-630 -66 -1172 -501 -1370 -1099 -134 -404 -106 -835 79
-1217 147 -303 427 -588 727 -739 521 -262 1136 -223 1628 103 159 106 339
286 443 443 327 494 365 1097 101 1625 -30 61 -85 153 -122 205 -85 121 -270
306 -392 392 -314 222 -714 327 -1094 287z m84 -238 c0 -99 20 -131 80 -131
60 0 80 32 80 131 l0 79 29 0 c16 0 80 -9 143 -19 236 -41 472 -148 660 -300
l58 -46 -49 -50 c-61 -62 -74 -88 -66 -126 8 -35 39 -59 78 -59 21 0 44 15 91
59 l62 60 46 -57 c173 -215 290 -499 314 -763 l7 -69 -82 0 c-74 0 -84 -2
-106 -25 -35 -34 -34 -86 1 -114 22 -17 41 -21 105 -21 l79 0 0 -34 c0 -51
-28 -201 -56 -301 -36 -131 -139 -334 -230 -455 l-76 -101 -57 54 c-31 30 -67
58 -80 62 -53 16 -112 -43 -96 -97 4 -13 31 -48 61 -78 l54 -56 -42 -35 c-132
-110 -352 -228 -513 -274 -103 -30 -240 -55 -296 -55 l-39 0 0 80 c0 73 -3 83
-25 105 -32 33 -78 33 -110 0 -22 -22 -25 -32 -25 -105 l0 -80 -37 0 c-55 0
-193 26 -298 55 -131 37 -331 139 -453 230 l-104 77 55 57 c30 31 58 66 62 79
16 54 -43 113 -96 97 -13 -4 -49 -32 -80 -62 l-57 -54 -76 101 c-91 121 -194
324 -230 455 -28 100 -56 250 -56 301 l0 34 79 0 c64 0 83 4 105 21 35 28 36
80 1 114 -22 23 -32 25 -106 25 l-82 0 7 69 c24 264 141 548 314 763 l46 57
62 -60 c47 -44 70 -59 91 -59 39 0 70 24 78 59 8 38 -5 64 -66 126 l-49 50 58
46 c130 105 277 186 443 244 102 36 277 73 352 74 l37 1 0 -79z"
        />
        <path
          d="M2706 2782 l-429 -187 -28 -57 c-16 -31 -108 -226 -204 -432 -182
-390 -187 -404 -147 -439 41 -36 50 -33 496 158 239 103 441 194 449 204 8 9
104 206 212 439 189 404 197 423 185 452 -15 36 -38 50 -77 49 -15 0 -221 -85
-457 -187z m292 -61 c-1 -5 -55 -119 -118 -255 l-115 -246 -142 142 c-79 78
-139 145 -135 149 9 9 494 218 505 219 5 0 7 -4 5 -9z m-493 -471 c76 -76 137
-141 134 -144 -8 -8 -514 -224 -517 -221 -5 5 230 505 237 505 4 0 69 -63 146
-140z"
        />
      </g>
    </svg>
  );
};

export default Campass;
