import React from "react";
import "./services.scss";
import Campass from "./SVG/Campass";
import Destination from "./SVG/Destination";
import Fire from "./SVG/Fire";

const Services = () => {
  return (
    <div className="services">
      <div className="services__top">
        <h1 className="services__title">Nos services</h1>
        <p className="services__para">
        Découvrez le meilleur du MAROC, si vous êtes un débutant absolu, un expert ou n'importe où entre les deux, nos visites sont parfaites pour tous ceux qui veulent découvrir et partager les merveilles du désert Montagne et plus encore...
        </p>
      </div>
      <div className="services__bottom">
        <div className="services__card">
          <Destination />
          <h1 className="services__card__title">Adventures</h1>
          <p className="services__card__desc">
          Nous passons notre vie à explorer la région, alors pourquoi ne pas nous laisser vous montrer nos maisons merveilleuses et des endroits plus intéressants au cours d'un ou plusieurs voyages guidés.!
          </p>
        </div>
        <div className="services__card">
          <Campass />
          <h1 className="services__card__title">Guides</h1>
          <p className="services__card__desc">
          Nous sommes heureux de vous conseiller sur le meilleur moyen de transport pour vous connecter avec un voyage de votre choix. Contactez-nous et nous serons heureux de vous proposer des options pertinentes pour votre visite. Une fois arrivé, nous viendrons vous chercher et notre aventure commencera!
          </p>
        </div>
        <div className="services__card">
          <Fire />
          <h1 className="services__card__title">Camping</h1>
          <p className="services__card__desc">
          Nous avons facilité la réservation - envoyez-nous simplement un e-mail avec votre visite et vos dates préférées et nous répondrons à toutes vos questions, vous enverrons des informations pertinentes, une fois que nous recevons votre message, votre place est sécurisée et il est temps de vous exciter!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
